import React from "react";

import api from "../../services/api";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import printIcon from "../../images/fax.png";

import {
    ButtonPrint,
    ImagePrint
} from "./styles";

export default function PrintProtocol({ value }){

    /*-------------------------------------------------------------------*/

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        
        var doc = {
            pageSize: "A4",
            pageOrientation: "portrait",
            pageMargins: [30, 30, 30, 30],
            content: []
        }

    /*-------------------------------------------------------------------*/

        function savePDF(){

            //Adicionar o cabeçalho 
            doc.content.push({
                text: 'Protocolo: ' + value.code + "\n\n\n",
                alignment: 'center',
                bold: true,
                fontSize: "18"
            });

            //Adicionar dados do protocolo
            doc.content.push({
                text: 
                    (value.open ? "Protocolo: Aberto\n" : "Protocolo: Fechado\n" ) +
                    "Status: " + value.state + "\n" +
                    "Cadastrado por: " + value.user_name_create + "\n" +
                    "Originado de: " + value.name_origin + "\n" +
                    "Destinado a: " + value.name_destiny + "\n" +
                    "Data: " + value.date + "\n" +
                    "Classificado como: " + value.classify + "\n" +
                    "Descrição: "+ value.describe + "\n",
                fontSize: "14",
            })

            //Adicioar processos do protocolo

            api.get(`process/${ value.id }`, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {

                    const data = res.data;

                    if( data.length > 0){
                        doc.content.push({
                            text:'___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                            alignment: 'center',
                            fontSize: 5,
                        })   
                    }

                    data.map((value_, index) => (
                        doc.content.push({
                            text:
                                "Criado por: " + value_.user_name_create + "\n" +
                                "Data: " + value_.date + "\n" +
                                "Título: " + value_.title + "\n" +
                                "Nota: " + value_.note + "\n\n",
                            
                            fontSize: "10",
                        })
                    ))

                    pdfMake.createPdf(doc).print({},window.open('', '_blank'));

                })
                .catch(error => {
                    console.log(error.response);
                })
            ;
        }

    return (
        <ButtonPrint onClick={ () => savePDF() } >
            <ImagePrint src={ printIcon } />
        </ButtonPrint>
    )
}
