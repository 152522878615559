import React, { useEffect } from "react";
import api from "../../services/api";
import {  
    AddClassifyForm,
    AddClassifyInput,
    AddClassifyLabel,
    AddClassifyButton
} from "./styles";

import { CloseButton } from "../../source/styles/Other";

export default function AddClassify({ setAddClassify, refresh, setRefresh, closeButton }){

    /*-------------------------------------------------------------------*/

        async function insertClassify(e){
            e.preventDefault();

            const data = { "classify": e.target.HTMLaddClassify.value.trim() };

            api.post('/classify', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                    setAddClassify(false);
                    return;
                })
                .catch(error => {
                    alert(error.response.data.error);
                    return;
                })
            ;
        }

    /*-------------------------------------------------------------------*/
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setAddClassify(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setAddClassify]);

    /*-------------------------------------------------------------------*/

    return(       
        <AddClassifyForm onSubmit={ insertClassify }>
            { (closeButton) && <CloseButton type={"button"} onClick={() => setAddClassify(false) } >X</CloseButton> }
            <AddClassifyLabel>
                Registrar nova classificação:
                <AddClassifyInput autoFocus placeholder={ "Documento..." } name={ "HTMLaddClassify" } type={ "text" } maxLength={ 60 } required />
            </AddClassifyLabel>
            <AddClassifyButton type={ 'submit' }>Inserir</AddClassifyButton>
        </AddClassifyForm>
    )
}