import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { Modal } from "../../source/styles/Other";

import Process from '../ListProcess';
import Received from "../Received";

import{
    StyleListProtocol,
    CardListProtocol,
    CardGetProtocol
}from './styles.js'

import { ButtonComponent } from "../../source/styles/Other";


export default function ManagerMyProtocols({ setManagerMyProtocols }){

    /*-------------------------------------------------------------------*/

        const [ data, setData ] = useState([{}]);
        const [ openProcess, setOpenProcess] = useState(false);
        const [ openProcessId, setOpenProcessId] = useState(null);
        const [ refresh, setRefresh ] = useState(false);

    /*-------------------------------------------------------------------*/

        function openProcessFunction({id}){ 
            if(!openProcess){
                setOpenProcessId(id);
                setOpenProcess(true);
            }
            return;
        }

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            api.get('protocol/my', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    setData(res.data.data);
                })
                .catch(error => {

                    // console.log(error.response)
                    setManagerMyProtocols(false);
                    alert(error.response.data.error);
                })
            ;
        },[setManagerMyProtocols, refresh]);

    /*-------------------------------------------------------------------*/

    return(
        <StyleListProtocol>
            <CardListProtocol>
            { 
                data.map((value, index) => (
                    (value.id !== undefined) &&
                    <div key={ index.toString() }>
                        <CardGetProtocol>
                                <label><b>{(value.open !== "0")? "Protocolo aberto" : "Protocolo fechado"}</b></label>
                                <label>Status: <u>{ value.state }</u></label>
                                <label>Código: <u>{ value.code }</u></label>
                                <label>Cadastrado por: <u>{ value.user_name_create }</u></label>
                                <label>Originado de: <u>{ value.name_origin }</u></label>
                                <label>Destinado a: <u>{ value.name_destiny }</u></label>
                                <label>Data: <u>{ value.date }</u></label>
                                <label>Classificado como: <u>{ value.classify }</u> </label>
                                <label>Descrição: <u>{ value.describe }</u></label>
                                <div>
                                
                                <ButtonComponent type={ 'button' } onClick={ () => openProcessFunction({"id": value.id}) }>Abrir Processos</ButtonComponent>
                                { 
                                    (value.open !== "0")
                                    ? 
                                        
                                        <Received 
                                            id={ value.id } 
                                            refresh={ refresh } 
                                            setRefresh={ setRefresh }
                                        />
                                    : 
                                        <></> 
                                }
                            </div>
                        </CardGetProtocol>
                    </div>
                ))}
            </CardListProtocol>
            {
                (openProcess) &&
                <Modal>
                    < Process setOpenProcess={ setOpenProcess } openProcessId={openProcessId} read={ true } />
                </Modal>
            }
        </StyleListProtocol>
    )
}