import React from 'react';
import Routes from './routers';

import GlobalStyle from './source/styles/global';

function App() {
  return (
    <>
      <GlobalStyle/>
      <Routes/>
    </>
  );
}

export default App;
