import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import api from "./services/api";

export default function PrivateRouter({ component: Component, ...res }) {
    
    const [ authorization, setAuthorization ] = useState( null );

    api.get('/dashboard', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
        .then(res => {
            setAuthorization(true);
        })
        .catch(error => {
            setAuthorization(false)
        })
    ;

    return(
        <Route
            { ...res }
            render = {
                props => 
                (authorization === null)
                ?
                    null
                : 
                    (authorization === true)
                ?
                    <Component { ...props }/>
                :
                    (
                        alert("Faça login para acessar esse conteudo"),
                        localStorage.removeItem("token-user-prosif-current"),
                        localStorage.removeItem("name-user-prosif-current"),
                        <Redirect to={{ pathname: "/", state:{ from: props.location } }}/>
                    )
            }
        
        />
    )
}