import React from "react";
import { MyFooter, AddressFooter } from './styles';

export default function Footer(){
    return(
        <MyFooter>
            <AddressFooter>
                Contate o suporte:
                <br/>
                <a href={ "mailto:suporte@sif.org.br" }>suporte@sif.org.br</a>
            </AddressFooter>
        </MyFooter>
    )
}