import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

    *{ 
        margin: 0;
        padding: 0;
        outline: 0;
        font-family: calibri, sans-serif;
        font-size: 1em;
    }

`;