import React, { useState, useEffect } from "react";
import api from '../../services/api';

import {
    ContainerManagerSource
} from "./styles";

import { 
    Modal,
    ContainerAdd,
    AddButton,
    SimpleList
} from "../../source/styles/Other";

import AddSource from "../AddSource";
import EditSource  from "../EditSource";

export default function ManagerSource(){
    
    /*-------------------------------------------------------------------*/
        
        const [ data, setData ] = useState([{}]) ;
        const [ addSource, setAddSource ] = useState(false);
        const [ refresh, setRefresh ] = useState(false);

    /*-------------------------------------------------------------------*/

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            
            api.get('/source', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    setData(res.data);
                })
                .catch(error => {
                    console.log(error.response.data);
                })
            ;

        },[refresh]);

    /*-------------------------------------------------------------------*/

    return(
        <ContainerManagerSource>
            <ContainerAdd>
                <AddButton type={ "button" } onClick={ () => setAddSource(!addSource) }> { (addSource)? <p>-</p> : <p>+</p> }</AddButton>
            </ContainerAdd>
            <SimpleList>
                {
                    data.map((value, index) => (
                        <div key={ index.toString() }>
                            <EditSource value={ value } setRefresh={ setRefresh } refresh={ refresh }/>
                        </div>
                    ))
                }
            </SimpleList>
            { 
                (addSource) &&
                <Modal>
                    <AddSource 
                        setAddSource={ setAddSource } 
                        refresh={ refresh } 
                        setRefresh={ setRefresh } 
                        sourceTipe={ "" }
                        closeButton={ true }
                    /> 
                </Modal>
            }
        </ContainerManagerSource>
    )
}