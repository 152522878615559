import React, { useState, useEffect } from "react";
import api from "../../services/api";
import moment from "moment";
import {
    ShowProcessButton,
    EditProcessContainer,
    EditProcessSubContainer,
    EditProcessForm,
    EditProcessFormLabel,
    UpdateProcessFormButton
} from "./styles";
import { Modal, CloseButton } from "../../source/styles/Other";


export default function EditProcess({ id_protocol }){

    /*-------------------------------------------------------------------*/

        const [ process, setProcess ] = useState([{}]);
        const [ listProcess, setListProcess ] = useState(false);

        const [ refresh, setRefresh ] = useState(false);


    /*-------------------------------------------------------------------*/

        function updateProcess(e){
            e.preventDefault();

            const data = {
                "id": parseInt(e.target.HTMLid.value),
                "user_name_update": e.target.HTMLuserupdate.value,
                "date_update": moment().format("DD/MM/YYYY hh:mm:ss"),
                "title": e.target.HTMLtitle.value,
                "note": e.target.HTMLnote.value
            }

            api.put('process', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    alert(res.data.sucess);
                    setRefresh(!refresh)
                })
                .catch(error => {
                    alert(error.response.data.error);
                })
            ;
        }

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            api.get(`/process/${id_protocol}`,{ headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    setProcess(res.data);
                })
                .catch(error => {
                    console.log(error.response.data.error);
                })
            ;
        },[id_protocol, refresh])

        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setListProcess(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setListProcess]);

    /*-------------------------------------------------------------------*/

    return(
        <>  
            {
                (process.length > 0) &&
                <ShowProcessButton type={"button"} onClick={() => setListProcess(!listProcess)} > { (listProcess)? "Ocultar processos" : "Mostar processos" }</ShowProcessButton>
            }
            {
                listProcess &&
                <Modal>
                    <EditProcessContainer>
                        <CloseButton onClick={() => setListProcess(false) } >X</CloseButton>
                        <EditProcessSubContainer>
                            {
                                process.map((value, index) => (
                                    <EditProcessForm key={ index.toString() } onSubmit={ updateProcess }>
                                        <input
                                            type={ "hidden" }
                                            name={ "HTMLid" }
                                            defaultValue={ value.id }
                                        />
                                        <EditProcessFormLabel>
                                            <b>Criado por:</b>
                                            <input
                                                type={ "text" }
                                                readOnly
                                                placeholder={ value.user_name_create }
                                            />
                                        </EditProcessFormLabel>
                                        <EditProcessFormLabel>
                                            <b>Será alterado por:</b>
                                            <input
                                                type={ "text" }
                                                name={ "HTMLuserupdate" }
                                                readOnly
                                                defaultValue={ localStorage.getItem("name-user-prosif-current") }
                                                placeholder={ localStorage.getItem("name-user-prosif-current") }
                                            />
                                        </EditProcessFormLabel>
                                        <EditProcessFormLabel>
                                            <b>Novo título:</b>
                                            <input
                                                type={ "text" }
                                                name={ "HTMLtitle" }
                                                defaultValue={ value.title }
                                            />
                                        </EditProcessFormLabel>
                                        <EditProcessFormLabel>
                                            <b>Nova nota:</b>
                                            <textarea
                                                name={ "HTMLnote" }
                                                defaultValue={ value.note }
                                            />
                                        </EditProcessFormLabel>
                                        <UpdateProcessFormButton type={ "submit" }>Atualizar</UpdateProcessFormButton>
                                    </EditProcessForm>
                                ))
                            }
                        </EditProcessSubContainer>
                    </EditProcessContainer>
                </Modal>
            }
        </>
    )
}