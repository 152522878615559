import React, { useState } from "react";
import api from "../../services/api";

import {
    ContainerConfirmed,
    ActionButton,
    ConfirmedConfirmationButton
} from "./styles";

export default function Received({ id, refresh, setRefresh, showProcess}) {
 
    /*-------------------------------------------------------------------*/

        const [ confirmReceived, setConfirmReceived ] = useState(false);

    /*-------------------------------------------------------------------*/
        function received() {

            const data = { id }

            api.post('protocol/received', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    console.log(error);
                    alert(error.response.data.error);
                })
            ;

            return;
        }
    /*-------------------------------------------------------------------*/

    return(
        <ContainerConfirmed>
            { (!confirmReceived) && <ActionButton type={ 'button' } onClick={ () => setConfirmReceived(!confirmReceived) }>Recebi</ActionButton> }
            { (confirmReceived) && <ConfirmedConfirmationButton type={ 'button' } onClick={ () => received() }>Confirmar</ConfirmedConfirmationButton> }
            { (confirmReceived) && <ActionButton type={ 'button' } onClick={ () => setConfirmReceived(!confirmReceived) }>Voltar</ActionButton> }
        </ContainerConfirmed>
    )
}