import React, { useState, useEffect } from "react";
import api from "../../services/api";

import {
    ContainerManagerMessagePredefined
} from "./styles";

import { 
    Modal,
    ContainerAdd,
    AddButton,
    SimpleList
} from "../../source/styles/Other";

import EditMessagePredefined from "../EditMessagePredefined";
import AddMessagePredefined from "../AddMessagePredefined";

export default function ManagerMessagePredefined(){

    /*-------------------------------------------------------------------*/

        const [ data, setData ] = useState([{}]);
        const [ addMessagePredefined, setAddMessagePredefined ] = useState(false);
        const [ refresh, setRefresh ] = useState(false);

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            api.get('/predefined', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    setData(res.data);
                })
                .catch(error => {
                    console.log(error.responce.data)
                })
            ;
        },[refresh]);

    /*-------------------------------------------------------------------*/

    return(
        <ContainerManagerMessagePredefined>
            <ContainerAdd>
                <AddButton onClick={ () => setAddMessagePredefined(!addMessagePredefined) }>{ (addMessagePredefined)? <p>-</p> : <p>+</p> }</AddButton>
            </ContainerAdd>
            <SimpleList>
                {
                    data.map((value, index) => (
                        <div key={ index.toString() } >
                            <EditMessagePredefined value={ value } setRefresh={ setRefresh } refresh={ refresh }/>
                        </div>
                    ))
                }
            </SimpleList>
            { 
                (addMessagePredefined) && 
                <Modal>
                    <AddMessagePredefined 
                        setAddMessagePredefined={ setAddMessagePredefined }  
                        refresh={ refresh } 
                        setRefresh={ setRefresh } 
                        sourceTipe={ "" }
                        closeButton={ true }
                    /> 
                </Modal>
            }
        </ContainerManagerMessagePredefined>
    )
}

