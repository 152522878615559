import React, { useState } from "react";
import api from "../../services/api";
import { 
    StyleSearch, 
    InputSearch, 
    ButtonSearh,
    FormSearch
} from "./styles"

import {
    ContentListNavigation, 
    ContentButtonLeftListNavigation,
    ContentLabelListNavigation,
    ContentButtonRigthListNavigation
} from "../ListProtocol/styles"


export default function Search({ setData, setSearch, useSearch, setPage, setOpenProcess, setMaxPage }) {

    /*-------------------------------------------------------------------*/

        const [ pageSearch, setPageSearch ] = useState(1);
        const [ maxPageSearch, setMaxPageSearch ] = useState(0);

    /*-------------------------------------------------------------------*/

        function mySearch(e) {
            e.preventDefault();

            var expression = e.target.HTMLsearch.value.trim()
            
            // se o usuário pesquisar alguma coisa com barra, essa irá ser confundida como limite de rotas pelo backend 
            expression = expression.replace("/", "---");
            
            if( expression !== ''){
                setSearch(true);

                api.get(`/search/${ expression }/${ pageSearch }`, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                    .then(res => {
                        setData(res.data.data);
                        setMaxPageSearch(res.data.pages);
                    })
                    .catch(error => {
                        console.log(error.response.data)
                        if(error.response.status === 404){
                            alert(error.response.data.error)
                        }
                        else{
                            console.log(error.response);
                        }
                    })
                ;
            }
            else{
                e.target.HTMLsearch.value = '';
                setSearch(false);
                setPage(1);

                api.get(`protocol/page/${1}`, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    setData(res.data.data);
                    setMaxPage(res.data.pages);
                })
                .catch(error => {
                    console.log(error);
                })
            ;
            }

            return;
        }

        function nextPage(){
            if(pageSearch < maxPageSearch){
                setPageSearch(pageSearch + 1);
                setOpenProcess(false);
            }
            return
        }

        function afterPage(){
            if(pageSearch > 1){
                setPageSearch(pageSearch - 1);
                setOpenProcess(false);
            }
            return
        }

    /*-------------------------------------------------------------------*/ 

    return(
        <>
            <StyleSearch>
                <FormSearch onSubmit={ mySearch }>
                    <InputSearch type={ "text" } name={ "HTMLsearch" } placeholder={ "Busca" }/>
                    <ButtonSearh type={ "submit" }> Buscar </ButtonSearh>
                </FormSearch>
            </StyleSearch>
            {
                ( useSearch ) &&
                <ContentListNavigation>
                    <ContentButtonLeftListNavigation type={ 'button' } onClick={ () => afterPage() }>Anterior</ContentButtonLeftListNavigation>
                    <ContentLabelListNavigation>Página { pageSearch }</ContentLabelListNavigation>
                    <ContentButtonRigthListNavigation type={ 'button' } onClick={ () => nextPage() }>Próxima</ContentButtonRigthListNavigation>
                </ContentListNavigation>
            }
        </>
    )

}