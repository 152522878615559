import React, { useCallback } from "react";
import moment from "moment";
import api from "../../services/api";

import { 
    AddProcessContainer,
    FormAddProcess,
    AddButtonProcess,
    TextAreaAddProcess,
    InputAddProcess
} from "./styles"
import { Modal, CloseButton } from "../../source/styles/Other";

export default function AddProcess({ setAddProcess, refresh, setRefresh, id_protocol }){

    /*-------------------------------------------------------------------*/
        
        const close = useCallback (() => {
            setAddProcess(false);
            return;
        },[setAddProcess])

        function registerProcess(e){
            e.preventDefault();

            const data = {
                "user_name_create": localStorage.getItem("name-user-prosif-current"),
                "date" : moment().format("DD/MM/YYYY hh:mm:ss"),
                "title": (e.target.HTMLaddProcessTitle.value).trim(),
                "note" : (e.target.HTMLaddProcessNote.value).trim(),
                "id_protocol": id_protocol
            }

            api.post('/process', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    alert(res.data.sucess);
                    setAddProcess(false);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    alert(error.response.data.error);
                    setAddProcess(false);
                })
            ;

            e.target.HTMLaddProcessTitle.value = "";
            e.target.HTMLaddProcessNote.value = "";
            
            return;
        }

    /*-------------------------------------------------------------------*/

    return(
        <Modal>
            <AddProcessContainer>
                <CloseButton type={ 'button' } onClick={ () => close() }>X</CloseButton>
                <FormAddProcess onSubmit={ registerProcess }>
                    <InputAddProcess placeholder={ "Título" } name={ "HTMLaddProcessTitle" } type={ "text" } maxLength={ 60 } required/>
                    <TextAreaAddProcess rows={ "10" } placeholder={ "Nota" } name={ "HTMLaddProcessNote" } type={ "text" } maxLength={ 120 } required/>
                    <AddButtonProcess type={ "submit" }>Cadastrar</AddButtonProcess>
                </FormAddProcess>
            </AddProcessContainer>
        </Modal>
    )
}