import styled from "styled-components";
import { theme } from "../../source/theme";

export const AddProcessContainer = styled.div`
    margin-top: 10px;
    padding: 20px;

    box-shadow: ${ theme.secundary } 0px 1px 4px;
    background-color: ${ theme.primary };

    border-radius: 2px;

    display: flex;
    flex-direction: column;
`;

export const FormAddProcess = styled.form`
    display: flex;
    flex-direction: column;
    
    align-items: stretch;
    
    margin-top: 10px;
    gap: 5px;
`;

export const AddButtonProcess = styled.button`
    margin-top: 10px;

    padding-top: 7px;
    padding-bottom: 7px;

    border-radius: 8px;

    border-width: 1px;
    
    box-shadow: ${ theme.secundary } 0px 1px 4px;
    
    border-radius: 2px;
`;

export const TextAreaAddProcess = styled.textarea`
    padding: 5px;
`;

export const InputAddProcess = styled.input`
    padding: 5px;
`;