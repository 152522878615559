import React from "react";

import {
    ContainerSignatureGenerator,
    MyHeaderSignatureGenerator,
    EditContainer,
    ViewContainer,
    ViewModelEmail,
    ContateInfomate
} from "./styles";


export default function SignatureGenerator(){
    return(

        <ContainerSignatureGenerator>
            <MyHeaderSignatureGenerator>
                <h1>Gerador de assinaturas </h1>
            </MyHeaderSignatureGenerator>
            <EditContainer>

            </EditContainer>
            <ViewContainer>
                <ViewModelEmail>
                    <header>
                        <h2>Nova mensagem</h2>
                        <div>
                            <span>_</span>
                            <span>/</span>
                            <span>X</span>
                        </div>
                    </header>
                    <div>
                        <ContateInfomate>
                            <span>Para</span>
                            <hr/>
                            <span>Assunto</span>
                            <hr/>
                        </ContateInfomate>
                    </div>
                </ViewModelEmail>
            </ViewContainer>
        </ContainerSignatureGenerator>

    )
}