import React, { useState } from "react";
import api from '../../services/api';
import { 
    ContainerErase,
    EraseConfirmationButton
} from "./styles";

import{
    ButtonComponent
}from "../../source/styles/Other";
 
export default function EraseSource({ erase_id, refresh, setRefresh }){

    /*-------------------------------------------------------------------*/

        const [ confirmErase, setConfirmErase ] = useState(false);

    /*-------------------------------------------------------------------*/
        
        function erase(){
            
            api.delete(`/source/${ erase_id }`, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    setConfirmErase(false);
                    setRefresh(!refresh);
                    alert(res.data.sucess);
                })
                .catch(error => {
                    setConfirmErase(false);
                    alert(error.response.data.error)
                })
            ;
            
            return;
        }
        
    /*-------------------------------------------------------------------*/

    return(
        <ContainerErase>
            { (!confirmErase) && <ButtonComponent type={ 'button' } onClick={ () => setConfirmErase(!confirmErase) }>Excluir</ButtonComponent> }
            { (confirmErase) && <ButtonComponent type={ 'button' } onClick={ () => setConfirmErase(!confirmErase) }>Cancelar</ButtonComponent> }
            { (confirmErase) && <EraseConfirmationButton type={ 'button' } onClick={ () => erase() }>Confirmar</EraseConfirmationButton> }
        </ContainerErase>
    )

}