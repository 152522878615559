import styled from "styled-components";

import { theme } from "../../source/theme";

export const MyFooter = styled.footer`
    grid-area: footer;

    display: flex;
    justify-content: center;
    
    padding-top: 5px;
    padding-bottom: 5px;

    background-color: ${ theme.ternary };
    border-top: 1px dashed ${ theme.secundary };
`;

export const AddressFooter = styled.footer`
`;