import React, { useState, useEffect }  from "react";
import api from "../../services/api";

import { 
    Modal,
    CloseButton,
    ButtonComponent
} from "../../source/styles/Other";

import{ 
    FormEditState,
    InputEditState,
    LabelEditState,
    ListButtonItem,
    ListEditStateContainer,
    UpdateEditStateButton,
} from "./styles";

import EraseState from "../EraseState";

export default function EditState({ value, refresh, setRefresh }){

    /*-------------------------------------------------------------------*/

        const [ show, setShow ] = useState(false);
        const old_state = value.state

    /*-------------------------------------------------------------------*/

        function updateState(e){
            e.preventDefault();

            const data = {
                "new_state": e.target.HTMLnewstate.value,
                old_state
            }

            api.put('/state', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    setShow(false);
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    alert(error.response.data.error);
                })
            ;

            return;
        }

    /*-------------------------------------------------------------------*/
    
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setShow(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setShow]);

    /*-------------------------------------------------------------------*/

    return(
        (show)
        ?
            <Modal>
                <FormEditState onSubmit={ updateState }>
                    <CloseButton onClick={ () => setShow(false) } >X</CloseButton>
                    <LabelEditState>
                        <b>Status do Documento:</b>
                        <InputEditState
                            autoFocus
                            name={ "HTMLnewstate" } 
                            type={ "text" } 
                            defaultValue={ value.state } 
                            required
                        />
                    </LabelEditState>
                    <UpdateEditStateButton>Atualizar</UpdateEditStateButton>
                </FormEditState>
            </Modal>
        :
            <ListEditStateContainer>
                <p>{ value.state }</p>
                <ListButtonItem>
                    <ButtonComponent onClick={ () => setShow(true) }>Editar</ButtonComponent>
                    <EraseState refresh={ refresh } setRefresh={ setRefresh } erase_id={ value.id }/> 
                </ListButtonItem>
            </ListEditStateContainer>
    )
}