import styled from "styled-components";

export const StyleSearch = styled.div`
grid-area: search;

display: flex;
justify-content: right;
`;

export const FormSearch = styled.form`
    display: flex;
`;

export const InputSearch = styled.input`
padding-left : 20px;
padding-right : 20px;
padding-bottom: 10px;
padding-top: 10px;

border-bottom-left-radius: 10px;
border-top-left-radius: 10px;

border-width: 1px;
`;

export const ButtonSearh = styled.button`
padding-left : 20px;
padding-right : 20px;
padding-bottom: 10px;
padding-top: 10px;

border-bottom-right-radius: 10px;
border-top-right-radius: 10px;

border-width: 1px;
`;