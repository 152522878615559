import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import DashboardAdmin from './pages/DashboardAdmin';
import DashboardUser from "./pages/DashboardUser";
import DashboardLobby from "./pages/DashboardLobby";
import Login from './pages/Login';
import Error from "./pages/Error";
import SignatureGenerator from "./pages/SignatureGenerator";

import PrivateRouter from "./PrivateRouter";

export default function Routes(){

    return(
        <BrowserRouter basename="/">
            <Switch>
                    <Route path={ "/" } exact component={ Login } />
                    <PrivateRouter path={ "/admin/dashboard" } exact component={ DashboardAdmin } />
                    <PrivateRouter path={ "/lobby/dashboard" } exact component={ DashboardLobby } />
                    <PrivateRouter path={ "/user/dashboard" } exact component={ DashboardUser } />
                    <Route path={ "/error" } exact component={ Error } />
                    <Route path={ "/assinatura" } exact component={ SignatureGenerator } />
            </Switch>
        </BrowserRouter>
    )
}