import styled from "styled-components";
import { theme } from "../../source/theme";

export const StyleListProtocol = styled.div`
    display: grid;
    grid-template-areas:
        "search"
        "list_content";

    justify-content: stretch;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;

    margin-bottom: 20px;
`;

export const ContentListNavigation = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;

    justify-self: center;
`;

export const ContentButtonLeftListNavigation = styled.button`
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;

    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

    border-width: 1px;
`;

export const ContentButtonRigthListNavigation = styled.button`
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    border-width: 1px;
`;

export const ContentLabelListNavigation = styled.label`
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
`;

export const CardListProtocol = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    justify-content: center;
`;

export const CardGetProtocol = styled.div`
    display: grid;
    grid-auto-columns: auto;
    grid-template-rows: 30px auto 30px;
    grid-template-areas:
        "options"
        "information"
        "process";

    justify-content: center;
    align-items: flex-start;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${theme.secundary} 0px 1px 3px -1px;
    border-radius: 2px;

    padding: 10px;

    width: 300px;

    /* teste */
    min-width: 300px;
    max-width: 300px;
    /* end teste */

    height: 480px;
    word-break: break-all;

    gap: 2px;
    padding-bottom: 30px;
`;

export const CardButton = styled.button`
    grid-area: process;

    margin-right: 20px;
    margin-left: 20px;

    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 10px;
    padding-top: 10px;

    border-radius: 8px;
    border-width: 1px;
    border-radius: 2px;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${theme.secundary} 0px 1px 3px -1px;
`;

export const Indicator = styled.div`
    background-color: transparent;

    border-width: 1px;
    border-radius: 9px;

    display: grid;
    justify-content: center;
    align-items: center;

    &:hover {
        #ToolTip {
            display: flex;
        }
    }
`;

export const ToolTip = styled.div`
    display: none;

    position: absolute;
    top: 1px;

    width: 300px;

    padding-top: 20px;
    padding-bottom: 20px;

    border-radius: 2px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${theme.primary} 0px 1px 3px -1px;

    &:hover {
        #ToolTip {
            display: grid;
        }
    }

    background-color: ${theme.secundary};
    color: ${theme.primary};

    flex-direction: column;
    align-items: center;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 1.5em;
        }
    }
`;

export const ImageIndicator = styled.img`
    width: 24px;
    height: 24px;
`;

export const OptionsContainer = styled.div`
    grid-area: options;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    position: relative;
`;

export const InformationContainer = styled.div`
    grid-area: information;
    display: flex;
    flex-direction: column;

    justify-content: stretch;
    align-items: center;

    gap: 10px;

    padding-left: 40px;
    padding-right: 40px;
`;

export const InformationCode = styled.h1`
    font-size: 22px;
    margin-bottom: 10px;
`;

export const InformationParagraph = styled.p`
    height: 35px;

    display: flex;

    text-align: center;
    overflow-y: auto;
    overflow-x: auto;

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${theme.secundary};
    }
`;

export const InformationLabel = styled.label`
    font-weight: 600;
    white-space: nowrap;

    margin-left: 8px;
    margin-right: 8px;
`;

export const InformationParagraphDescribe = styled.p`
    max-height: 80px;

    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${theme.secundary};
    }
`;
