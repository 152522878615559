import React, { useState, useEffect } from "react";
import api from "../../services/api";

import {
    FormEditClassify,
    InputEditClassify,
    UpdateEditSourceButton,
    ListEditClassifyContainer,
    ListButtonItem
} from "./styles";

import EraseClassify from "../EraseClassify";
import { CloseButton, Modal, ButtonComponent } from "../../source/styles/Other";

export default function EditClassify({ value, refresh, setRefresh }){

    /*-------------------------------------------------------------------*/

        const [show, setShow] = useState(false);

    /*-------------------------------------------------------------------*/

        function updateClassify(e){
            e.preventDefault();

            const data = {
                "id": e.target.HTMLid.value,
                "classify": e.target.HTMLclassify.value,
            }

            api.put('/classify', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    setShow(false);
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    alert(error.response.data.error);
                })

            return;
        }

    /*-------------------------------------------------------------------*/
        
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setShow(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setShow]);

    /*-------------------------------------------------------------------*/

    return(
        (show)
        ?
            <Modal>
                <FormEditClassify onSubmit={ updateClassify }>
                    <CloseButton onClick={ () => setShow(false) } >X</CloseButton>
                    <InputEditClassify
                        name={ "HTMLid" } 
                        type={ "hidden" } 
                        defaultValue={ value.id } 
                        required 
                        readOnly
                    />
                    <InputEditClassify
                        autoFocus
                        name={ "HTMLclassify" } 
                        type={ "text" } 
                        defaultValue={ value.classify } 
                        required
                    />
                    <UpdateEditSourceButton>Atualizar</UpdateEditSourceButton>
                </FormEditClassify>
            </Modal>
        :
            <ListEditClassifyContainer>
                <p>{ value.classify }</p>
                <ListButtonItem>
                    <ButtonComponent onClick={ () => setShow(true) } >Editar</ButtonComponent>
                    <EraseClassify erase_id={ value.id } setRefresh={ setRefresh } refresh={ refresh }/>
                </ListButtonItem>
            </ListEditClassifyContainer>

    )
}