import React, { useEffect } from "react";
import api from "../../services/api";

import {  
    AddUserForm,
    AddUserInput,
    AddUserLabel,
    AddUserButton,
    AddUserSelect
} from "./styles";

import { CloseButton } from "../../source/styles/Other";

export default function AddUser({ setAddUser, refresh, setRefresh, closeButton }){
    /*-------------------------------------------------------------------*/

        async function addUser(e){
            e.preventDefault();

            const data = {
                "name": e.target.HTMLname.value,
                "password": e.target.HTMLpassword.value,
                "password_confirmation": e.target.HTMLpasswordconfirmation.value,
                "level_acess": e.target.HTMLlevelacess.value
            }

            api.post('/user', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    alert(res.data.sucess);
                    setAddUser(false);
                    setRefresh(!refresh)
                })
                .catch(error => {
                    alert(error.response.data.error);
                })
            ;

            return;

        }

    /*-------------------------------------------------------------------*/
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setAddUser(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setAddUser]);

    return(
        <AddUserForm onSubmit={ addUser }>
            { (closeButton) && <CloseButton type={"button"} onClick={() => setAddUser(false) } >X</CloseButton> }
            <AddUserLabel>
                Nome do usuário: 
                <AddUserInput
                    autoFocus
                    name={ "HTMLname" }
                    defaultValue={ "" }
                    type={ "text" }
                    required
                />
            </AddUserLabel>
            <AddUserLabel>
                Senha do usuário: 
                <AddUserInput
                    name={ "HTMLpassword" }
                    type={ "text" }
                    defaultValue={ "" }
                    required
                />
            </AddUserLabel>
            <AddUserLabel>
                Confirme a senha do usuário: 
                <AddUserInput 
                    name={ "HTMLpasswordconfirmation" }
                    type={ "text" } 
                    defaultValue={ "" }
                    required
                />
            </AddUserLabel>
            <AddUserLabel>
                Nível de acesso:
                <AddUserSelect name={ "HTMLlevelacess" } required >
                    <option value={ 0 }>ROOT</option>
                    <option value={ 1 }>Portaria</option>
                    <option value={ 2 }>Leitor</option>
                </AddUserSelect>
            </AddUserLabel>
            <AddUserButton type="submit">Cadastrar Usuário</AddUserButton>
        </AddUserForm>
    )
}