import styled from "styled-components";
import { theme } from "../../source/theme";

export const ShowProcessButton = styled.button`
    margin-top: 5px;

    padding-top: 8px;
    padding-bottom: 8px;

    border-radius: 8px;

    border-width: 1px;

    box-shadow: ${ theme.secundary } 0px 0px 2px;

    border-radius: 2px;
`;

export const EditProcessContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${ theme.ternary };

    padding: 10px;

    gap: 10px;

    box-shadow: ${ theme.secundary } 0px 1px 4px;
    
    max-height: 400px;
    min-width: 300px;
`;

export const EditProcessSubContainer = styled.div`
    padding: 5px;

    overflow-y: auto;

    ::-webkit-scrollbar{
        width: 4px;
    };
    
    ::-webkit-scrollbar-thumb{
        background: ${ theme.secundary };
    };

    display: grid;
    gap: 20px;
`;

export const EditProcessForm = styled.form`
    display: flex;
    flex-direction: column;

    gap: 8px;
`;

export const EditProcessFormLabel = styled.label`
    display: flex;
    flex-direction: column;

    & > input{
        padding: 5px;
    }

    & > textarea {
        padding: 5px;
    }
`;

export const UpdateProcessFormButton = styled.button`
    margin-top: 5px;

    padding-top: 8px;
    padding-bottom: 8px;

    border-radius: 8px;

    border-width: 1px;

    box-shadow: ${ theme.secundary } 0px 0px 2px;

    border-radius: 2px;
`;