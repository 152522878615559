import styled from "styled-components";
import { theme } from "../../source/theme";

export const ContainerLogin = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 60px;
    grid-template-areas:
        "logo login"
        "logo signature";

    height: 100vh;

    justify-content: center;
    align-items: center;

    background-color: ${theme.primary};
`;

export const ContainerForm = styled.form`
    grid-area: login;

    padding: 20px;

    display: grid;

    justify-content: center;
    align-items: center;

    gap: 5px;
`;

export const ImageShowPassword = styled.img`
    width: 20px;
    height: 20px;
`;

export const ButtonShowPassword = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.primary};
`;

export const ContainerPassword = styled.div`
    display: flex;
    gap: 2px;
`;

export const InputLogin = styled.input`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 5px;
    border-radius: 5px;
    border-width: 1px;
`;

export const ButtonLogin = styled.button`
    padding: 5px;
    background-color: ${theme.secundary};
    color: white;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    border-radius: 5px;
    border-width: 1px;

    :hover {
        transition: 500ms;
        background-color: ${theme.ternary};
    }
`;

export const ContainerLogo = styled.div`
    grid-area: logo;
    background-color: ${theme.secundary};

    height: 100vh;

    display: grid;
    justify-content: center;
    align-items: center;
`;

export const SubContainerLogo = styled.div`
    display: grid;

    & > h1 {
        font-size: 5em;
        color: ${theme.primary};
    }
`;
