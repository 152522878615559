import React, { useState, useEffect } from "react";
import moment from "moment";
import api from "../../services/api";

import { RegisterContainer, RegisterForm, RegisterFormLabel, RegisterFormOption, RegisterFormLabelDiv, RegisterFormSelect, RegisterAddButton, RegisterFormTextarea, PreviewContainer, RegisterButton, AddAreaContainer } from "./styles";

import AddSource from "../AddSource";
import AddClassify from "../AddClassify";
import AddState from "../AddState";
import { Modal } from "../../source/styles/Other";

export default function RegisterProtocol() {
    /*-------------------------------------------------------------------*/

    const [source, setSource] = useState([{}]);
    const [classify, setClassify] = useState([{}]);
    const [myState, setMyState] = useState([{}]);
    const [addSourceOrigin, setAddSourceOrigin] = useState(false);
    const [addSourceDestiny, setAddSourceDestiny] = useState(false);
    const [addClassify, setAddClassify] = useState(false);
    const [addState, setAddState] = useState(false);
    const [predefined, setPredefined] = useState([{}]);

    const [refresh, setRefresh] = useState(true);

    /*-------------------------------------------------------------------*/

    function cleanForm(e) {
        e.target.HTMLdescribe.value = "";
    }

    async function insertProtocol(e) {
        e.preventDefault();

        const data = {
            state: e.target.HTMLmyState.value,
            user_name_create: localStorage.getItem("name-user-prosif-current"),
            date: moment().format("DD/MM/YYYY HH:mm:ss"),
            classify: e.target.HTMLclassify.value,
            origin: e.target.HTMLorigin.value,
            destiny: e.target.HTMLdestiny.value,
            describe: e.target.HTMLdescribe.value,
        };

        api.post("/protocol", data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
            .then((res) => {
                alert("Protocolo Cadastrado, codigo: [ " + res.data.code + " ]");
                cleanForm(e);
            })
            .catch((error) => {
                console.log(error);
                alert(error.response.data.error);
            });

        return;
    }

    function clickAddState() {
        setAddState(!addState);
        setAddClassify(false);
        setAddSourceDestiny(false);
        setAddSourceOrigin(false);
    }

    function clickAddClassify() {
        setAddClassify(!addClassify);
        setAddState(false);
        setAddSourceDestiny(false);
        setAddSourceOrigin(false);
    }

    function clickAddSourceDestiny() {
        setAddSourceDestiny(!addSourceDestiny);
        setAddClassify(false);
        setAddState(false);
        setAddSourceOrigin(false);
    }

    function clickAddSourceOrigin() {
        setAddSourceOrigin(!addSourceOrigin);
        setAddSourceDestiny(false);
        setAddClassify(false);
        setAddState(false);
    }

    function getPreviewMessagePredefined(i, m) {
        let preview = m ? m : "";

        preview = preview.substr(0, 30);

        return i + 1 + " - " + preview + "...";
    }

    /*-------------------------------------------------------------------*/

    useEffect(() => {
        api.get("/source", { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
            .then((res) => {
                setSource(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [refresh]);

    useEffect(() => {
        api.get("/classify", { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
            .then((res) => {
                setClassify(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [addClassify]);

    useEffect(() => {
        api.get("/state", { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
            .then((res) => {
                setMyState(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [addState]);

    useEffect(() => {
        api.get("/predefined", { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
            .then((res) => {
                setPredefined(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [addState]);
    /*-------------------------------------------------------------------*/

    return (
        <RegisterContainer>
            <RegisterForm onSubmit={insertProtocol}>
                <RegisterFormLabel>
                    <b>Status:</b>
                    <RegisterFormLabelDiv>
                        <RegisterFormSelect name={"HTMLmyState"} required>
                            {myState.map((value, index) => (
                                <RegisterFormOption key={index.toString()} value={value.id}>
                                    {value.state}
                                </RegisterFormOption>
                            ))}
                        </RegisterFormSelect>
                        <RegisterAddButton type={"button"} onClick={() => clickAddState()}>
                            {" "}
                            {addState ? "-" : "+"}{" "}
                        </RegisterAddButton>
                    </RegisterFormLabelDiv>
                </RegisterFormLabel>
                <RegisterFormLabel>
                    <b>Classificação:</b>
                    <RegisterFormLabelDiv>
                        <RegisterFormSelect name={"HTMLclassify"} required>
                            {classify.map((value, index) => (
                                <RegisterFormOption key={index.toString()} value={value.id}>
                                    {value.classify}
                                </RegisterFormOption>
                            ))}
                        </RegisterFormSelect>
                        <RegisterAddButton type={"button"} onClick={() => clickAddClassify()}>
                            {" "}
                            {addClassify ? "-" : "+"}{" "}
                        </RegisterAddButton>
                    </RegisterFormLabelDiv>
                </RegisterFormLabel>
                <RegisterFormLabel>
                    <b>Origem:</b>
                    <RegisterFormLabelDiv>
                        <RegisterFormSelect name={"HTMLorigin"} required>
                            {source.map((value, index) => (
                                <RegisterFormOption key={index.toString()} value={value.id}>
                                    {value.name}
                                </RegisterFormOption>
                            ))}
                        </RegisterFormSelect>
                        <RegisterAddButton type={"button"} onClick={() => clickAddSourceOrigin()}>
                            {" "}
                            {addSourceOrigin ? "-" : "+"}{" "}
                        </RegisterAddButton>
                    </RegisterFormLabelDiv>
                </RegisterFormLabel>
                <RegisterFormLabel>
                    <b>Destino:</b>
                    <RegisterFormLabelDiv>
                        <RegisterFormSelect name={"HTMLdestiny"} required>
                            {source.map((value, index) => (
                                <RegisterFormOption key={index.toString()} value={value.id}>
                                    {value.name}
                                </RegisterFormOption>
                            ))}
                        </RegisterFormSelect>
                        <RegisterAddButton type={"button"} onClick={() => clickAddSourceDestiny()}>
                            {" "}
                            {addSourceDestiny ? "-" : "+"}{" "}
                        </RegisterAddButton>
                    </RegisterFormLabelDiv>
                </RegisterFormLabel>
                <RegisterFormLabel>
                    <b>Descrição:</b>
                    <RegisterFormLabelDiv>
                        <RegisterFormSelect
                            name={"HTMLpredefined"}
                            onChange={(e) => {
                                document.getElementById("HTMLdescribe_").value = e.target.value;
                            }}
                            required>
                            <RegisterFormOption value={""}>-</RegisterFormOption>
                            {predefined.map((value, index) => (
                                <RegisterFormOption key={index.toString()} value={value.message}>
                                    {getPreviewMessagePredefined(index, value.message)}
                                </RegisterFormOption>
                            ))}
                        </RegisterFormSelect>
                    </RegisterFormLabelDiv>
                    <RegisterFormTextarea name={"HTMLdescribe"} id={"HTMLdescribe_"} cols={"30"} rows={"10"} maxLength={500} />
                </RegisterFormLabel>
                <RegisterButton type={"submit"}>Cadastrar</RegisterButton>
            </RegisterForm>
            <AddAreaContainer>
                {addState && (
                    <Modal>
                        <AddState setAddState={setAddState} refresh={refresh} setRefresh={setRefresh} closeButton={true} />
                    </Modal>
                )}
                {addClassify && (
                    <Modal>
                        <AddClassify setAddClassify={setAddClassify} refresh={refresh} setRefresh={setRefresh} closeButton={true} />
                    </Modal>
                )}
                {addSourceOrigin && (
                    <Modal>
                        <AddSource setAddSource={setAddSourceOrigin} refresh={refresh} setRefresh={setRefresh} sourceTipe={"Origem"} closeButton={true} />
                    </Modal>
                )}
                {addSourceDestiny && (
                    <Modal>
                        <AddSource setAddSource={setAddSourceDestiny} refresh={refresh} setRefresh={setRefresh} sourceTipe={"Destino"} closeButton={true} />
                    </Modal>
                )}
            </AddAreaContainer>
            <PreviewContainer>{/* Preview */}</PreviewContainer>
        </RegisterContainer>
    );
}
