import styled from "styled-components";
import { theme } from "../../source/theme";

export const ContainerSignatureGenerator = styled.div`
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 80px auto;
    grid-template-areas: 
        "myHeader    myHeader"
        "edit       view"
    ;

    height: 100vh;

`

export const MyHeaderSignatureGenerator = styled.header`
    grid-area: myHeader;

    background-color: ${ theme.primary };
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${ theme.secundary } 0px 1px 3px -1px;

    display:  flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    & > h1{
        font-size: 2em;
        color: ${ theme.secundary };
    }
`;

export const EditContainer = styled.div`
    grid-area: edit;

    background-color: ${ theme.secundary };

    height: 100%;
`;

export const ViewContainer = styled.div`
    grid-area: view;

    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

export const ViewModelEmail = styled.div`

    grid-area: myHeader;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;


    width: 580px;

    & > header{
    
        display: flex;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        justify-content: space-between;

        background-color: #565555;
        padding: 10px;
        
        & > h2 {
            color: white;
        }
        
        & > div{
            display: flex;
            gap: 20px;
    
            font-weight: bold;
    
            color: #C8C7C7  ;
        }

    }


    & > div{
        /* background-color: red; */

        height: 550px;
    }

`;

export const ContateInfomate = styled.div`
    
    background-color: white;
    
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    & > span {
        color: #777;
    }

    & > hr {
        border: 0.1em solid #777;
    }
`;