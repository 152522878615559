import styled from "styled-components";
import { theme } from "../../source/theme";

export const ModuleEditProtocolContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    box-shadow: ${ theme.secundary } 0px 1px 4px;
    padding: 20px;

    background-color: ${ theme.ternary };
`;

export const ModuleListProtocolContainer = styled.div`
    display: grid;
    justify-content: center;
`;

export const ModuleListProtocolSubContainer = styled.div`
    display: grid;
    grid-template-columns: 30px repeat(5, 150px);
    font-size: 1em;
    
    padding: 10px;
    margin-bottom: 10px;
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${ theme.secundary } 0px 1px 3px -1px;
    border-radius: 2px;
    
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px){
        grid-template-columns: repeat(6, 120px);
        font-size: 16px;
    }
    
    @media (max-width: 1000px){
        grid-template-columns: repeat(6, 100px);
        font-size: 14px;    
    }

    & > button{
        @media (max-width: 800px){
            font-size: 13px;
        }
    }
    
`;

export const ModuleListProtocolItem = styled.div`
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;

    & > p{
        overflow-y: auto;
        max-height: 60px;
        word-wrap: break-word;
        ::-webkit-scrollbar{
            width: 0;
        }
    }
    
    @media (max-width: 800px){
        font-size: 13px;
    }
`;


export const ModuleEditProtocolForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ModuleEditProtocolLabel = styled.label`
    display: flex;
    flex-direction: column;
`;

export const ModuleEditProtocolLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ModuleEditProtocolSelect = styled.select`
    display: flex;
    width: 100%;
    padding: 5px;

    ::-webkit-scrollbar{
        width: 4px;
        height: 4px;
    };
    
    ::-webkit-scrollbar-thumb{
        background: ${ theme.secundary };
    };
`;

export const ModuleEditProtocolTextArea = styled.textarea`
    padding: 10px;
`;

export const ModuleEditProtocolAddButton = styled.button`
    width: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModuleEditProtocolInput = styled.input`
    padding: 5px;
`;

export const ModuleEditProtocolUpdateButtonDiv = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: -15px;
    margin-top: -10px;

    gap: 10px;
`;

export const ModuleEditProtocolUpdateButton = styled.button`
    align-self: flex-end;
    width: 20px;
    height: 20px;
    border-width: 0px;

    display: grid;
    justify-content: center;
    align-content: center;

    padding: 15px;
`;

export const ModuleEditProtocolImage = styled.img`
justify-self: center;
    align-self: center;
    width: 22px;
    height: 22px;
`;

export const ImageIndicator = styled.img`
    width: 15px;
    height: 15px;
`;