import styled from "styled-components";
import { theme } from "../../source/theme";

export const Modal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3000;

    background-color: rgba(10,23,55,0.5);
`;

export const CloseButton = styled.button`
    align-self: flex-end;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border-width: 1px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${ theme.secundary } 0px 1px 3px -1px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
`;

export const ContainerAdd = styled.div`
    display: flex;
    justify-content: end;
    position: absolute;

    right: 10px;
    bottom: 60px;
`;

export const AddButton = styled.button`
    width: 40px;
    height: 40px;
    font-size: 2em;
    border-radius: 20px;
    border-width: 1px;
    border-color: ${ theme.secundary };
    margin-right: 20px;
    
    color: ${ theme.secundary };

    display: flex;
    justify-content: center;
    align-items: center;

    & > p{
        margin-bottom: 2px;
        margin-left: 1px;
    }

`;

export const ButtonComponent = styled.button`
    padding-left : 5px;
    padding-right : 5px;
    padding-bottom: 4px;
    padding-top: 4px;

    border-radius: 3px;

    border-width: 1px;

    box-shadow: rgba(50, 50, 93, 0.25) 1px 2px 0px -5px, ${ theme.secundary } 0px 1px 3px -1px;
    margin-bottom: 10px;
    margin-top: 10px;
`;


export const SimpleList = styled.div`

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 700px;
    
    word-break: break-all;
    gap: 20px;

    margin-top: 40px;
    margin-right: 20px;
    margin-left: 20px;

    & > div{
        
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${ theme.secundary } 0px 1px 3px -1px;
        border-radius: 2px;
        
        & > div{
            padding: 10px;
        }
    }

    @media (max-width: 550px){
        width: 500px;

    }
    
`;

