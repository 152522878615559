import React, { useState, useEffect } from "react";
import api from "../../services/api";
import moment from "moment";
import {
    ModuleEditProtocolContainer,
    ModuleEditProtocolForm,
    ModuleEditProtocolLabel,
    ModuleEditProtocolLabelContainer,
    ModuleEditProtocolSelect,
    ModuleEditProtocolAddButton,
    ModuleEditProtocolInput,
    ModuleEditProtocolUpdateButton,
    ModuleEditProtocolImage,
    ModuleEditProtocolUpdateButtonDiv,
    ModuleEditProtocolTextArea,
    ModuleListProtocolContainer,
    ModuleListProtocolSubContainer,
    ModuleListProtocolItem,
    ImageIndicator
} from "./styles";

import { CloseButton, Modal, ButtonComponent }from "../../source/styles/Other";

import AddSource from '../AddSource';
import AddClassify from "../AddClassify";
import AddState from "../AddState";
import EditProcess from "../EditProcess";

import Save from "../../images/save.png";
import On from "../../images/on.png";
import Off from "../../images/off.png";

export default function ModuleEditProtocol({ value, index, setRefreshProtocols, refreshProtocols }){

    /*-------------------------------------------------------------------*/

    const [ source, setSource ] = useState([{}]);
    const [ classify, setClassify ] = useState([{}]);
    const [ myState, setMyState ] = useState([{}]);
    const [ addSourceOrigin, setAddSourceOrigin ] = useState(false);
    const [ addSourceDestiny, setAddSourceDestiny ] = useState(false);
    const [ addClassify, setAddClassify ] = useState(false);
    const [ addState, setAddState ] = useState(false);
    const [ refresh, setRefresh ] = useState(true);
    const [ show, setShow ] = useState(false);

    /*-------------------------------------------------------------------*/

    function updateProtocol(e){
        e.preventDefault();

        const data = {
            "id": e.target.HTMLid.value,
            "open": parseInt(e.target.HTMLopen.value),
            "state": parseInt(e.target.HTMLstate.value),
            "user_name_create": localStorage.getItem("name-user-prosif-current"),
            "date": moment().format("DD/MM/YYYY hh:mm:ss"),
            "classify": parseInt(e.target.HTMLclassify.value),
            "describe": e.target.HTMLdescribe.value,
            "origin": parseInt(e.target.HTMLorigin.value),
            "destiny": parseInt(e.target.HTMLdestiny.value)
        }

        api.put('/protocol', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
            .then(res => {
                alert(res.data.sucess);
                setRefreshProtocols(!refreshProtocols);
                setShow(!show);
            })
            .catch(error => {
                alert(error.response.data.error)
            })
        ;
        return;
    }

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            api.get('/source', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    setSource(res.data);
                })
                .catch(error => {
                    console.log(error);
                })
            ;
        },[refresh]);

        useEffect(() => {
            api.get('/classify', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res =>{
                    setClassify(res.data)
                })
                .catch(error => {
                    console.log(error);
                })
            ;
        },[addClassify]);

        useEffect(() => {
            api.get('/state', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    setMyState(res.data)
                })
                .catch(error => {
                    console.log(error);
                })
            ;
        },[addState]);
        
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setShow(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setShow]);


    /*-------------------------------------------------------------------*/
    
    return(
        (show)
        ?
            <Modal>
                <ModuleEditProtocolContainer key={ index.toString() }>
                    <ModuleEditProtocolForm onSubmit={ updateProtocol }>
                        <ModuleEditProtocolUpdateButtonDiv>
                            <ModuleEditProtocolUpdateButton type={ 'submit' }>
                                <ModuleEditProtocolImage src={ Save }/>
                            </ModuleEditProtocolUpdateButton>
                        <CloseButton type={ "button" } onClick={ () => setShow(false) }>
                            X
                        </CloseButton>
                        </ModuleEditProtocolUpdateButtonDiv>
                        <input type={ "hidden" } name={ "HTMLid" } defaultValue={ value.id } required/>
                        <ModuleEditProtocolLabel>
                            <b>O Protocolo se encontra:</b>
                            <ModuleEditProtocolSelect name={ "HTMLopen" }  defaultValue={ value.open } required>
                                    <option value={ 1 }>Aberto</option>
                                    <option value={ 0 }>Fechado</option>
                            </ModuleEditProtocolSelect>
                        </ModuleEditProtocolLabel>
                        <ModuleEditProtocolLabel>
                            <b>Status:</b>
                            <ModuleEditProtocolLabelContainer>
                                <ModuleEditProtocolSelect name={ "HTMLstate" } defaultValue={ value.id } required>
                                    <option value={ value.id_state }>{ value.state }</option>
                                    {
                                        myState.map((v, index) => (
                                            (v.id !== value.id_state) && 
                                            <option key={ index.toString() } value={ v.id } >{ v.state }</option>
                                        ))
                                    }
                                </ModuleEditProtocolSelect>
                                <ModuleEditProtocolAddButton type={ 'button' } onClick={ () => setAddState(!addState) }> { (addState) ? "-" : "+" } </ModuleEditProtocolAddButton>
                            </ModuleEditProtocolLabelContainer>
                        </ModuleEditProtocolLabel>
                        <ModuleEditProtocolLabel>
                            <b>Código:</b>
                            <ModuleEditProtocolInput 
                                type={ "text" }
                                name={ "HTMLcode" }
                                readOnly
                                placeholder={ value.code }
                                defaultValue={ value.code }
                            />
                        </ModuleEditProtocolLabel>
                        <ModuleEditProtocolLabel>
                            <b>Protocolo criado por { value.user_name_create } e será alterado por:</b>
                            <ModuleEditProtocolInput 
                                type={ "text" }
                                name={ "HTMLname" }
                                readOnly
                                placeholder={ localStorage.getItem("name-user-prosif-current") }
                                defaultValue={ localStorage.getItem("name-user-prosif-current") }
                            />
                        </ModuleEditProtocolLabel>
                        <ModuleEditProtocolLabel>
                            <b>Originado de:</b>
                            <ModuleEditProtocolLabelContainer>
                                <ModuleEditProtocolSelect name={ "HTMLorigin" }>
                                    <option value={ value.id_origin } >{ value.name_origin }</option>
                                    { 
                                        source.map((v, index) =>  (
                                            (v.name !== value.name_origin) && 
                                            <option key={ index.toString() } value={ v.id }>{ v.name }</option>
                                        ))
                                    }
                                </ModuleEditProtocolSelect>
                                <ModuleEditProtocolAddButton type={ 'button' } onClick={ () => setAddSourceOrigin(!addSourceOrigin) }> { (addSourceOrigin) ? "-" : "+" } </ModuleEditProtocolAddButton>
                            </ModuleEditProtocolLabelContainer>
                        </ModuleEditProtocolLabel>
                        <ModuleEditProtocolLabel>
                            <b>Destinado a:</b>
                            <ModuleEditProtocolLabelContainer>
                                <ModuleEditProtocolSelect name={ "HTMLdestiny" }>
                                <option value={ value.id_destiny }>{ value.name_destiny }</option>
                                    { 
                                        source.map((v, index) =>  (
                                            (v.id !== value.id_destiny) && 
                                            <option key={ index.toString() } value={ v.id }>{ v.name }</option>
                                        ))
                                    }
                                </ModuleEditProtocolSelect>
                                <ModuleEditProtocolAddButton type={ 'button' } onClick={ () => setAddSourceDestiny(!addSourceDestiny) }> { (addSourceDestiny) ? "-" : "+" } </ModuleEditProtocolAddButton>
                            </ModuleEditProtocolLabelContainer>
                        </ModuleEditProtocolLabel>
                        <ModuleEditProtocolLabel>
                            <b>Classificação:</b>
                            <ModuleEditProtocolLabelContainer>
                                <ModuleEditProtocolSelect name={ "HTMLclassify" } required>
                                    <option value={ value.id_classify } >{ value.classify }</option>
                                    {
                                        classify.map((v, index) => (
                                            (v.id !== value.id_classify) && 
                                            <option key={index.toString() }  value={ v.id }>{ v.classify }</option>
                                        ))
                                    }
                                </ModuleEditProtocolSelect>
                                <ModuleEditProtocolAddButton type={ 'button' } onClick={ () => setAddClassify(!addClassify) }> { (addClassify) ? "-" : "+" } </ModuleEditProtocolAddButton>
                            </ModuleEditProtocolLabelContainer>
                        </ModuleEditProtocolLabel>
                        <ModuleEditProtocolLabel>
                            <b>Descrição:</b>
                            <ModuleEditProtocolTextArea
                                name={ "HTMLdescribe" }
                                defaultValue={ value.describe }
                                maxLength={ 500 }
                            />
                        </ModuleEditProtocolLabel>
                    </ModuleEditProtocolForm>

                    <EditProcess id_protocol={ value.id } />

                    { 
                        (addState) &&
                        <Modal>
                            <AddState 
                                setAddState={ setAddState }  
                                refresh={ refresh } 
                                setRefresh={ setRefresh }
                                closeButton={ true }
                            /> 
                        </Modal>
                    }
                    {   
                        (addSourceDestiny) && 
                        <Modal>
                            <AddSource 
                                setAddSource={ setAddSourceDestiny } 
                                refresh={ refresh } 
                                setRefresh={ setRefresh } 
                                sourceTipe={ "Destino" }
                                closeButton={ true }
                            />
                        </Modal> 
                    }
                    {   
                        (addSourceOrigin) &&
                        <Modal>
                                <AddSource 
                                    setAddSource={ setAddSourceOrigin } 
                                    refresh={ refresh } 
                                    setRefresh={ setRefresh } 
                                    sourceTipe={ "Origem" }
                                    closeButton={ true }
                                /> 
                        </Modal>
                    }
                    {   
                        (addClassify) &&
                        <Modal>
                            <AddClassify 
                                setAddClassify={ setAddClassify } 
                                refresh={ refresh } 
                                setRefresh={ setRefresh }
                                closeButton={ true }
                            />
                        </Modal> 
                    }
                
                </ModuleEditProtocolContainer>
            </Modal>
        :
            <ModuleListProtocolContainer>
                <ModuleListProtocolSubContainer>
                    <ModuleListProtocolItem>{ (value.open === "1")? <ImageIndicator src={ On } />: <ImageIndicator src={ Off } />}</ModuleListProtocolItem>
                    <ModuleListProtocolItem><p>{ value.code }</p></ModuleListProtocolItem>
                    <ModuleListProtocolItem><p>{ "De: " + value.name_origin }</p></ModuleListProtocolItem>
                    <ModuleListProtocolItem><p>{ "Para: " + value.name_destiny }</p></ModuleListProtocolItem>
                    <ModuleListProtocolItem><p>{ value.classify }</p></ModuleListProtocolItem>
                    <ButtonComponent onClick={ () => setShow(!show) }>Editar</ButtonComponent>
                </ModuleListProtocolSubContainer>
            </ModuleListProtocolContainer>
    )

    
}