import styled from "styled-components";
import { theme } from "../../source/theme";

export const FormEditClassify = styled.form`
    display: flex;
    flex-direction: column;

    width: 300px;
    padding: 10px;

    gap: 5px;

    background-color: ${ theme.ternary };
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${ theme.secundary } 0px 1px 3px -1px;

    border-radius: 2px;
`;

export const InputEditClassify = styled.input`
    padding: 5px;
`;

export const UpdateEditSourceButton = styled.button`
    margin-top: 10px;

    padding-left : 10px;
    padding-right : 10px;
    padding-bottom: 5px;
    padding-top: 5px;

    border-radius: 8px;

    border-width: 1px;
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${ theme.secundary } 0px 1px 3px -1px;
    
    border-radius: 2px;
`;

export const ListEditClassifyContainer = styled.div`
    display: flex;

    justify-content: space-between;
    align-items: center;
    
    &>p{
        word-break: break-all;
    }
`;

export const ListButtonItem = styled.div`
    display: flex;
    align-items: center;

    padding-left: 20px;
    padding-right: 10px;

    gap: 10px;

    background-color: transparent;
    border: none;
`;