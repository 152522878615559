import React, { useEffect } from "react";
import api from "../../services/api";
import {  
    AddStateForm,
    AddStateInput,
    AddStateLabel,
    AddStateButton
} from "./styles";

import { CloseButton } from "../../source/styles/Other";

export default function AddState({ setAddState, refresh, setRefresh, closeButton }){

    /*-------------------------------------------------------------------*/

        async function insertState(e){
            e.preventDefault();

            const data = { "state": e.target.HTMLaddState.value.trim() };

            api.post('/state', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                    setAddState(false);
                    return;
                })
                .catch(error => {
                    console.log(error)

                    alert(error.response.data.error);
                    return;
                })
            ;
        }

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setAddState(false);
            }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setAddState]);

    /*-------------------------------------------------------------------*/

    return(
        <>         
            <AddStateForm onSubmit={ insertState }>
                { (closeButton) && <CloseButton type={"button"} onClick={() => setAddState(false) } >X</CloseButton> }
                <AddStateLabel>
                    Registrar um novo Status:
                    <br/>
                    <AddStateInput autoFocus name={ "HTMLaddState" } type={ "text" } placeholder={ "Saindo..." } maxLength={ 60 } required/>
                </AddStateLabel>
                <AddStateButton type={ 'submit' }>Inserir</AddStateButton>
            </AddStateForm>
        </>
)
}