import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { useHistory } from 'react-router-dom';

import { 
    AddButton, 
    ContainerAdd, 
    Modal,
    SimpleList
} from "../../source/styles/Other";

import {
    ContainerManagerUser
} from "./styles";

import AddUser from "../AddUser";
import EditUser from "../EditUser";

export default function ManagerUser(){

    /*-------------------------------------------------------------------*/
        
        const [ data, setData ] = useState([{}]);
        const [ addUser, setAddUser ] = useState(false);
        const [ refresh, setRefresh ] = useState(false);
        const history = useHistory();

    /*-------------------------------------------------------------------*/
       
        useEffect(() => {
            api.get('/user', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    setData(res.data);
                })
                .catch(error => {
                    alert("Por gentileza, faça login novamente");
                    
                    localStorage.removeItem("token-user-prosif-current");
                    localStorage.removeItem("name-user-prosif-current");
                    history.replace('/');

            })
            ;
        },[refresh, history]);
    
    /*-------------------------------------------------------------------*/
    
    return(
        <ContainerManagerUser>
            <ContainerAdd>
                <AddButton type={ "button" } onClick={ () => setAddUser(!addUser) }>{ (addUser)? <p>-</p> : <p>+</p> }</AddButton>
            </ContainerAdd>
            <SimpleList>
                {
                    data.map((value, index) => (
                        <div key={ index.toString() }>
                            <EditUser refresh={ refresh } setRefresh={ setRefresh } value={ value } />
                        </div>
                    ))
                }
            </SimpleList>
            { 
                (addUser) &&
                <Modal>
                    <AddUser 
                        setAddUser={ setAddUser } 
                        refresh={ refresh } 
                        setRefresh={ setRefresh } 
                        closeButton={ true }
                    /> 
                </Modal>
            }
        </ContainerManagerUser>
    )
}