import React from "react";
import { useHistory } from 'react-router-dom';

export default function Error(){

    const history = useHistory();
    
    return(
        <>
            <h1>Erro na conexão com o servidor</h1>
            <button onClick={ () =>  history.push('/') } >Tentar novamente</button>
        </>
    )
}