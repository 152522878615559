import styled, { keyframes } from "styled-components";
import { theme } from "../source/theme";

const TopEffect = keyframes`
    from { top: -100px; }
    to { top: 0px; };
`;

const BoottomEffect = keyframes`
    from { width: 0%; }
    to { width: 100% ; };
`;

export const Display = styled.div`
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-template-rows: 1fr 20fr 1fr;
    grid-template-areas:
        "menu   title_header"
        "menu   content"
        "menu   footer"
    ;

    height: 100vh;

    @media (max-width: 800px){
        grid-template-columns: 1fr;

        grid-template-areas:
            "title_header"
            "content"
            "footer"
        ;

        justify-content: center;
    }
`;

export const MenuToggle = styled.div`
    @media (min-width: 800px){
        display: none;
    }
    z-index: 1000;
`;

export const SubMenuToggle = styled.div`
    background-color: ${ theme.secundary };

    position: absolute;

    top: 0;
    padding-top: 60px;
    padding-bottom: 10px;


    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 2px;
    
    animation: ${ TopEffect };
    animation-duration: 1s;
    animation-fill-mode: forwards;
`;

export const Menu = styled.header`
    grid-area: menu;
    
    display: grid;
    grid-template-rows: 1fr 20fr 1fr;
    grid-template-areas: 
        "logo"
        "funct"
        "log_out"
    ;

    padding-top: 10px;
    
    background-color: ${theme.secundary};
    
    min-width: 220px;

    @media (max-width: 800px){
        display: none;
        height: 200vh;
    }
`;

export const SubMenu = styled.header`
    grid-area: funct;

    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    flex-direction: column;
`;

export const TitleHeaderContainer = styled.div`
    grid-area: title_header;

    display: flex;
    justify-content: left;

    padding-left: 40px;
    padding-top: 10px;
    
    background-color: ${theme.primary};

    & > h1{
        color: ${theme.secundary};
        font-size: 3em;
    }
`;

export const TitleHeader = styled.h1`
`;

export const Content = styled.div`
    grid-area: content;

    background-color: ${theme.primary};
    
    overflow-y: auto;

    ::-webkit-scrollbar{
        width: 4px;
        height: 4px;
    };
    
    ::-webkit-scrollbar-thumb{
        background: ${theme.secundary};
    };
`;

export const ButtonMenu = styled.button`
    padding: 5px;
`;

export const ButtonMenuToggle = styled.button`
    padding: 5px;

    margin-left: 40px;
    margin-right: 40px;
`;

export const IconContainer = styled.div`
    grid-area: logo;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 20px;

    & > img{
        width: 7em;
    } 
`;

export const ImageToggleMenuIcon = styled.img`
    width: 25px;
    height: 25px;
`;

export const ToggleButtonMenuIcon = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px;

    position: absolute;
    
    right: 10px;
    top: 10px;

    z-index: 2000;
`;

export const BoldToggleMenuIcon = styled.b`
    width: 25px;
    height: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${theme.secundary};
`;

export const SubMenuToggleContainerOut = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    & > button{
        border: 2px solid ${ theme.secundary };
        padding: 15px;
    }

    position: absolute;
    bottom: 0px;

    animation: ${ BoottomEffect };
    animation-duration: 0.8s;
    animation-fill-mode:  forwards;
`;