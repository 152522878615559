import styled from "styled-components";
import { theme } from "../../source/theme";

export const StyleListProtocol = styled.div`
    display: grid;
    grid-template-areas:
    "search"
    "list_content"
    ;

    justify-content: stretch;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
`;

export const CardListProtocol = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    justify-content: center;
`;

export const CardGetProtocol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${ theme.secundary } 0px 1px 3px -1px;
    border-radius: 2px;

    padding: 10px;

    width: 300px;
    height: 300px;
    word-break: break-all;

`;