import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

import { ContainerLogin, ContainerForm, ImageShowPassword, ButtonShowPassword, ContainerPassword, InputLogin, ButtonLogin, ContainerLogo, SubContainerLogo, SignatureContainer } from "./styles";

import { Modal } from "../../source/styles/Other";

import ViewEyes from "../../images/view.png";
import HiddenEyes from "../../images/hidden.png";

export default function Login() {
    /*-------------------------------------------------------------------*/

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [myInput, setMyInput] = useState(false);

    const userLogin = localStorage.getItem("name-user-prosif-current");
    const userToken = localStorage.getItem("token-user-prosif-current");

    const history = useHistory();

    /*-------------------------------------------------------------------*/

    async function LoginUser(e) {
        e.preventDefault();
        setMyInput(true);

        const data = { name: name, password: password };

        await api
            .post("/login", data)
            .then((res) => {
                console.log(res);
                localStorage.setItem("token-user-prosif-current", res.data.token);
                localStorage.setItem("name-user-prosif-current", res.data.name);
                localStorage.setItem("theme-user-prosif-current", "white");

                const level_acess = res.data.level_acess;

                if (level_acess === "0") {
                    history.replace("/admin/dashboard");
                } else if (level_acess === "1") {
                    history.replace("/lobby/dashboard");
                } else if (level_acess === "2") {
                    history.replace("/user/dashboard");
                } else {
                    history.replace("/error");
                }
            })
            .catch((error) => {
                if (error.response) {
                    setMyInput(false);
                } else {
                    history.replace("/error");
                }

                localStorage.removeItem("token-user-prosif-current");
                localStorage.removeItem("name-user-prosif-current");
            });

        return;
    }

    function getAuthorization() {
        api.get("/dashboard", { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
            .then((res) => {
                console.log(res.data);

                const level_acess = res.data.level_acess;
                if (level_acess === "0") {
                    history.replace("/admin/dashboard");
                } else if (level_acess === "1") {
                    history.replace("/lobby/dashboard");
                } else if (level_acess === "2") {
                    history.replace("/user/dashboard");
                } else {
                    localStorage.removeItem("token-user-prosif-current");
                    localStorage.removeItem("name-user-prosif-current");

                    history.push("/");
                }
                return;
            })
            .catch((error) => {
                localStorage.removeItem("token-user-prosif-current");
                localStorage.removeItem("name-user-prosif-current");

                window.location.reload();
            });
    }

    /*-------------------------------------------------------------------*/

    return !userToken || !userLogin ? (
        <ContainerLogin>
            {
                // impedir que o usuário clique em algo enquanto faz login
                myInput && <Modal></Modal>
            }
            <ContainerLogo>
                <SubContainerLogo>
                    <h1>ProSif</h1>
                </SubContainerLogo>
            </ContainerLogo>
            <ContainerForm onSubmit={LoginUser}>
                <InputLogin type="text" required placeholder={"Nome"} value={name} onChange={(e) => setName(e.target.value.replace(" ", ""))} autoComplete={"on"} />
                <ContainerPassword>
                    <InputLogin type={!showPassword ? "password" : "text"} required placeholder={"Senha"} value={password} onChange={(e) => setPassword(e.target.value.replace(" ", ""))} autoComplete={"on"} />
                    <ButtonShowPassword type="button" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <ImageShowPassword src={HiddenEyes} /> : <ImageShowPassword src={ViewEyes} />}
                    </ButtonShowPassword>
                </ContainerPassword>
                <ButtonLogin>Login</ButtonLogin>
            </ContainerForm>
        </ContainerLogin>
    ) : (
        <>{getAuthorization()}</>
    );
}
