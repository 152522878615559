import styled from "styled-components";
import { theme } from "../../source/theme";

export const ContainerErase = styled.div`
    display: flex;
    flex-direction: column;
`;

export const EraseConfirmationButton = styled.button`
    margin-top: 10px;

    background-color: #ff0e0e;
    color: white;

    padding-left : 10px;
    padding-right : 10px;
    padding-bottom: 5px;
    padding-top: 5px;

    border-radius: 8px;

    border-width: 1px;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, ${ theme.secundary } 0px 1px 3px -1px;

    border-radius: 2px;
`;