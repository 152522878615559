import styled from "styled-components";
import { theme } from "../../source/theme";

export const EditProtocolContainer = styled.div`
    display: grid;
    grid-template-areas:
        "navigate"
        "mycontent"
    ;

    justify-content: center;

    margin-bottom: 40px;
    overflow-x: hidden;
`;

export const ContentListNavigation = styled.div`
    grid-area: navigate;

    margin-top: 20px;
    margin-bottom: 60px;
    justify-self: center;
`;

export const ContentButtonLeftListNavigation = styled.button`
    padding-left : 20px;
    padding-right : 20px;
    padding-bottom: 5px    ;
    padding-top: 5px;
    
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    
    border-width: 1px;
`;

export const ContentButtonRigthListNavigation = styled.button`
    padding-left : 20px;
    padding-right : 20px;
    padding-bottom: 5px;
    padding-top: 5px;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    border-width: 1px;
`;

export const ContentLabelListNavigation = styled.label`
    padding-left : 20px;
    padding-right : 20px;
    padding-bottom: 5px;
    padding-top: 5px;
`;

export const ModuleEditProtocolArea = styled.div`
    grid-area: mycontent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    padding-left: 40px;
    padding-right: 40px;

    gap: 10px;
    overflow-x: auto;

    ::-webkit-scrollbar{
        height: 4px;
    };
    
    ::-webkit-scrollbar-thumb{
        background: ${ theme.secundary };
    };
`;

