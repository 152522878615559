import React, { useEffect } from "react";
import api from "../../services/api";
import {  
    AddMessagePredefinedForm,
    AddMessagePredefinedInput,
    AddMessagePredefinedLabel,
    AddMessagePredefinedButton
} from "./styles";

import { CloseButton } from "../../source/styles/Other";

export default function AddMessagePredefined({ setAddMessagePredefined, refresh, setRefresh, closeButton }){

    /*-------------------------------------------------------------------*/

        async function insertMessagePredefined(e){
            e.preventDefault();

            const data = { "message": e.target.HTMLmessagePredefinedInput.value.trim() };

            api.post('/predefined', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                    setAddMessagePredefined(false);
                    return;
                })
                .catch(error => {
                    alert(error.response.data.error);
                    return;
                })
            ;
        }

    /*-------------------------------------------------------------------*/
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setAddMessagePredefined(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setAddMessagePredefined]);

    /*-------------------------------------------------------------------*/

    return(       
        <AddMessagePredefinedForm onSubmit={ insertMessagePredefined }>
            { (closeButton) && <CloseButton type={"button"} onClick={() => setAddMessagePredefined(false) } >X</CloseButton> }
            <AddMessagePredefinedLabel>
                Registrar nova mensagem predefinida:
                <AddMessagePredefinedInput 
                    autoFocus 
                    placeholder={ "Texto..." } 
                    name={ "HTMLmessagePredefinedInput" } 
                    type={ "text" } 
                    cols={ "30" } 
                    rows={ "10" }
                    maxLength={ 500 }
                    required 
                />
            </AddMessagePredefinedLabel>
            <AddMessagePredefinedButton type={ 'submit' }>Inserir</AddMessagePredefinedButton>
        </AddMessagePredefinedForm>
    )
}