import React, { useState } from "react";
import { 
    Menu, 
    Display, 
    Content, 
    ButtonMenu,
    TitleHeaderContainer,
    MenuToggle,
    SubMenu,
    ImageToggleMenuIcon,
    ToggleButtonMenuIcon,
    BoldToggleMenuIcon,
    SubMenuToggle,
    ButtonMenuToggle,
    SubMenuToggleContainerOut,
    IconContainer
} from '../styles';

import Logout from "../../components/Logout";
import Footer from "../../components/Footer";
import ManagerMyProtocols from "../../components/ManagerMyProtocolos";

import ImageIcon from "../../images/AplicLogoSIF.png";
import ToggleMenuIcon from "../../images/menu.png";

export default function Dashboard(){

    /*-------------------------------------------------------------------*/
    
        const [ managerMyProtocols, setManagerMyProtocols ] = useState(false);
        const [ showToggle, setShowToggle ] = useState(false);

        const selected = (e) => { 
            if(e){ return { opacity: '.8' } }
            else{ return {} } 
        };

    /*-------------------------------------------------------------------*/
        function closeAll(){
            setManagerMyProtocols(false);
            setShowToggle(false);
        }

        function clickManagerMyProtocols(){
            if(!managerMyProtocols){
                closeAll();
                setManagerMyProtocols(true);
            } 
        }
    /*-------------------------------------------------------------------*/

    return(
        <Display>
            <Menu>
                <IconContainer>
                    <img src={ ImageIcon } alt=""/>
                </IconContainer>
                <SubMenu>
                    <ButtonMenu style={ selected(managerMyProtocols) } onClick={ () => clickManagerMyProtocols() }>Meus Protocolos</ButtonMenu>
                </SubMenu>
                <Logout/>
            </Menu>
            <MenuToggle>
                <ToggleButtonMenuIcon 
                    onClick={
                        () => setShowToggle(!showToggle) 
                    }
                >
                    { 
                        (showToggle)
                        ? 
                            <BoldToggleMenuIcon>X</BoldToggleMenuIcon>
                        : 
                            <ImageToggleMenuIcon src={ ToggleMenuIcon }/> 
                    }
                </ToggleButtonMenuIcon>
                {
                    (showToggle) &&
                    <>
                        <SubMenuToggle showToggle={ showToggle } >
                            <ButtonMenuToggle style={ selected(managerMyProtocols) } onClick={ () => clickManagerMyProtocols() }>Meus Protocolos</ButtonMenuToggle>
                        </SubMenuToggle>
                        <SubMenuToggleContainerOut>
                            <Logout/>
                        </SubMenuToggleContainerOut>
                    </>
                }
            </MenuToggle>
            <TitleHeaderContainer>
                <h1>Protocolo Sif</h1>
            </TitleHeaderContainer>
            <Content>
                { managerMyProtocols && ( <ManagerMyProtocols setManagerMyProtocols={ setManagerMyProtocols }/> ) }
            </Content>
            <Footer/>
        </Display>
    )
}