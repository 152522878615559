import styled from "styled-components";
import { theme } from "../../source/theme";

export const ListProcessContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: stretch;

    padding: 20px;

    width: 300px;

    box-shadow: ${ theme.secundary } 0px 1px 4px;
    border-radius: 2px;

    background-color: white;
`;

export const ListProcess = styled.div`
    height: 250px;
    padding-top: 10px;

    overflow-y: auto;

    ::-webkit-scrollbar{
        width: 4px;
    };
    
    ::-webkit-scrollbar-thumb{
        background: ${ theme.secundary };
    };
`;

export const CloseButton = styled.button`

    align-self: flex-end;
    justify-self: flex-end;

    width: 30px;
    height: 30px;

    padding: 5px;

    border-radius: 15px;
    box-shadow: ${ theme.secundary } 0px 1px 4px;

    border-width: 1px;

`;

export const ListProcessTitle = styled.h1`
    align-self: center;
    margin-bottom: 1ex;
`;

export const AddButton = styled.button`
    margin-top: 10px;

    padding-top: 15px;
    padding-bottom: 15px;

    border-radius: 8px;

    border-width: 1px;
    
    box-shadow: ${ theme.secundary } 0px 1px 4px;
    
    border-radius: 2px;
`;
