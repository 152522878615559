import React from "react"
import { useHistory } from 'react-router-dom';
import { ButtonMenuOut } from './styles.js';


export default function Logout() {

    /*-------------------------------------------------------------------*/

        const history = useHistory();

    /*-------------------------------------------------------------------*/
    
        async function logoutUser() {
            localStorage.removeItem("token-user-prosif-current");
            localStorage.removeItem("name-user-prosif-current");
        
            history.replace('/');
            
            return;
        }
    
    /*-------------------------------------------------------------------*/

    return(
        <ButtonMenuOut onClick={ () => logoutUser() } > Sair </ButtonMenuOut>
    )

}
