import React, { useState, useEffect }  from "react";
import api from "../../services/api";

import { 
    CloseButton, 
    Modal,
    ButtonComponent
}from "../../source/styles/Other";

import {
    FormEditUser,
    InputEditUser,
    SelectEditInput,
    LabelEditUser,
    UpdateEditUserButton,
    ListEditUserContainer,
    ListButtonItem,
} from "./styles";

import EraseUser from "../EraseUser";

export default function EditUser({ value, refresh, setRefresh }){
    <hr/>
    /*-------------------------------------------------------------------*/

        const [ show, setShow ] = useState(false);
        const [ showPassword, setShowPassword ] = useState(false);
        const old_name = value.name;

    /*-------------------------------------------------------------------*/

        function update(e){
            e.preventDefault();

            const data = {
                "new_name": e.target.HTMLnew_name.value,
                old_name,
                "password": e.target.HTMLpassword.value,
                "password_confirmation": e.target.HTMLpassword_confirmation.value,
                "level_acess": e.target.HTMLlevel_acess.value,
            }

            api.put('/user', data,  { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    alert(res.data.sucess);
                    setShow(false);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    alert(error.response.data.error);
                    setRefresh(!refresh);
                })
            ;
            
            
            e.target.HTMLpassword.value = '';
            e.target.HTMLpassword_confirmation.value = '';

            return;
        }

    /*-------------------------------------------------------------------*/
    
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setShow(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setShow]);

    /*-------------------------------------------------------------------*/

    return(
        (show)
        ?
            <Modal>
                <FormEditUser onSubmit={ update }>
                    <CloseButton onClick={ () => setShow(false) } >X</CloseButton>
                    <LabelEditUser>
                        <b>Nome:</b>
                        <InputEditUser autoFocus name={ "HTMLnew_name" } type={ "text" } defaultValue={ value.name } required/>
                    </LabelEditUser>
                    <LabelEditUser>
                        <b>Nova senha:</b>
                        <InputEditUser name={ "HTMLpassword" } type={ (showPassword)?"text":"password" } defaultValue={ "" } placeholder={ "Nova senha" }/>
                    </LabelEditUser>
                    
                        <LabelEditUser>
                            <b>Confirme a nova senha:</b>
                            <InputEditUser name={ "HTMLpassword_confirmation" } type={ (showPassword)?"text":"password" } defaultValue={ "" } placeholder={ "Confirme nova a senha" }/>
                        </LabelEditUser>
                    <LabelEditUser>

                        <b>Nível de acesso:</b>
                        <SelectEditInput name={ "HTMLlevel_acess" } type={ "text" } defaultValue={ value.level_acess } required>
                            <option value={ 0 }>ROOT</option>
                            <option value={ 1 }>Portaria</option>
                            <option value={ 2 }>Destinatário Cadastrado</option>
                            <option value={ 3 }>Leitura</option>
                        </SelectEditInput>
                    </LabelEditUser>
                    <LabelEditUser>
                        <b>Mostrar senha:</b>
                        <InputEditUser
                            type="checkbox"
                            value = { showPassword }
                            onChange = {e => setShowPassword(!showPassword)}
                        />
                    </LabelEditUser>
                    <UpdateEditUserButton type={ "submit" }>Atualizar</UpdateEditUserButton>
                </FormEditUser>
            </Modal>
        :
            <ListEditUserContainer>
                <p>{ value.name }</p>
                <ListButtonItem>
                    <ButtonComponent onClick={ () => setShow(true) }>Editar</ButtonComponent>
                    <EraseUser refresh={ refresh } setRefresh={ setRefresh } erase_id={ value.id }/>
                </ListButtonItem>
            </ListEditUserContainer>
    )

}