import React, { useState, useEffect } from "react";
import api from "../../services/api";

import {
    ContainerManagerClassify
} from "./styles";

import { 
    Modal,
    ContainerAdd,
    AddButton,
    SimpleList
} from "../../source/styles/Other";

import EditClassify from "../EditClassify";
import AddClassify from "../AddClassify";

export default function ManagerClassify(){

    /*-------------------------------------------------------------------*/

        const [ data, setData ] = useState([{}]);
        const [ addClassify, setAddClassify ] = useState(false);
        const [ refresh, setRefresh ] = useState(false);

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            api.get('/classify', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    setData(res.data);
                })
                .catch(error => {
                    console.log(error.responce.data)
                })
            ;
        },[refresh]);

    /*-------------------------------------------------------------------*/

    return(
        <ContainerManagerClassify>
            <ContainerAdd>
                <AddButton onClick={ () => setAddClassify(!addClassify) }>{ (addClassify)? <p>-</p> : <p>+</p> }</AddButton>
            </ContainerAdd>
            <SimpleList>
                {
                    data.map((value, index) => (
                        <div key={ index.toString() } >
                            <EditClassify value={ value } setRefresh={ setRefresh } refresh={ refresh }/>
                        </div>
                    ))
                }
            </SimpleList>
            { 
                (addClassify) && 
                <Modal>
                    <AddClassify 
                        setAddClassify={ setAddClassify }  
                        refresh={ refresh } 
                        setRefresh={ setRefresh } 
                        sourceTipe={ "" }
                        closeButton={ true }
                    /> 
                </Modal>
            }
        </ContainerManagerClassify>
    )
}

