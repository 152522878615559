import styled from "styled-components";
import { theme } from "../../source/theme";


export const AddUserForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 300px;

    box-shadow: ${ theme.secundary } 0px 3px 8px;

    background-color: ${ theme.ternary };
`;

export const AddUserLabel = styled.label`
    display: flex;
    flex-direction: column;
    gap: 1px;
`;

export const AddUserInput = styled.input`
    padding: 4px;
`;

export const AddUserButton = styled.button`
    background-color: ${ theme.secundary };
    color: ${ theme.primary };

    padding-top: 5px;
    padding-bottom: 5px;

    :hover{
        transition: 500ms;
        background-color: ${ theme.primary };
        color: ${  theme.secundary };
    }
`;

export const AddUserSelect = styled.select`
    padding: 5px;

    ::-webkit-scrollbar{
        width: 4px;
        height: 4px;
    };
    
    ::-webkit-scrollbar-thumb{
        background: ${ theme.secundary };
    };
`;