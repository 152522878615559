import styled from "styled-components";
import { theme } from "../../source/theme";

export const ButtonPrint = styled.button`
    border: none;
    background-color: ${ theme.primary };

    width: 25px;
    height: 25px;

    :active{
        background-color: gray;
    }
`;

export const ImagePrint = styled.img`
    width: 25px;
    height: 25px;
`;

export const InformationContainer = styled.div`

`;