import styled from "styled-components";
import { theme } from "../../source/theme";

export const ButtonMenuOut = styled.button`
    grid-area: log_out;
    
    background-color: ${ theme.ternary };

    padding-top: 13px;
    padding-bottom: 13px;
`;