import React, { useState, useEffect, useCallback } from "react";
import api from "../../services/api";
import AddProcess from '../AddProcess';

import { 
    ListProcessContainer,
    CloseButton,
    AddButton,
    ListProcess,
    ListProcessTitle
} from "./styles";

export default function Process({setOpenProcess, openProcessId, read}){

    /*-------------------------------------------------------------------*/
    
        const [ data, setData] = useState([{}]);
        const [ addProcess, setAddProcess ] = useState(false);
        const [ refresh, setRefresh ] = useState(false);

    /*-------------------------------------------------------------------*/

        const close = useCallback (() => {
            setOpenProcess(false);
            return;
        },[setOpenProcess])

        function addProcessFunction() {
            setAddProcess(!addProcess);
            return
        }

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            api.get(`process/${ openProcessId }`, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    setData(res.data);
                })
                .catch(error => {
                    console.log(error.response);
                })
            ;
        },[openProcessId, refresh])

        useEffect(() => {
            const handleEsc = (event) => {
               if (event.keyCode === 27) {
                close();
              }
            };
            window.addEventListener('keydown', handleEsc);
        }, [close]);

    /*-------------------------------------------------------------------*/

    return(
        <ListProcessContainer>
            <CloseButton type={ 'button' } onClick={ () => close() }>X</CloseButton>
            <ListProcessTitle>Processos</ListProcessTitle>
            <ListProcess>
                {
                    data.map((value, index) => (
                        <div key={ index.toString() }>
                            <label>
                                <p> Criado por: { value.user_name_create } </p>
                            </label>
                            <label>
                                <p> Data: { value.date } </p>
                            </label>
                            <label>
                                <p> Título: { value.title } </p>
                            </label>
                            <label>
                                <p> Nota: { value.note } </p>
                            </label>
                            <hr/>
                        </div>
                    ))
                }
            </ListProcess>
            {
                !read && 
                (
                    <>
                        <AddButton type={ 'button' } onClick={ () => addProcessFunction() }> { (addProcess)? "Recolher" : "Adicionar" } </AddButton>
                        { 
                            (addProcess) &&  
                            <AddProcess  setAddProcess={ setAddProcess } refresh={ refresh } setRefresh={ setRefresh } id_protocol={ openProcessId }/>
                        }
                    </>
                )
            }
        </ListProcessContainer>
    )
}