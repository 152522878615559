import React, { useState, useEffect } from "react";
import api from '../../services/api';
import { mask } from "remask";

import { 
    CloseButton, 
    Modal,
    ButtonComponent
}from "../../source/styles/Other";

import {
    FormEditSource,
    LabelEditSource,
    InputEditSouce,
    SelectEditInput,
    UpdateEditSourceButton,
    ListEditSourceContainer,
    ListButtonItem
} from "./styles"; 

import EraseSource from "../EraseSource";

export default function EditSource({ value, refresh, setRefresh }){

    /*-------------------------------------------------------------------*/

        const [ show, setShow ] = useState(false);
    
    /*-------------------------------------------------------------------*/

        async function updateSource(e){
            e.preventDefault();
            
            const data = {
                "id": e.target.HTMLid.value,
                "name": (e.target.HTMLaddSourceName.value).trim(),
                "road": (e.target.HTMLaddSourceRoad.value).trim(),
                "number": (e.target.HTMLaddSourceNumber.value).trim(),
                "complement": (e.target.HTMLaddSourceComplement.value).trim(),
                "district": (e.target.HTMLaddSourceDistrict.value).trim(),
                "cep": (e.target.HTMLaddSourceCep.value).trim(),
                "state_country": (e.target.HTMLaddSourceState_country.value).trim(),
                "city": (e.target.HTMLaddSourceCity.value).trim(),
                "fone": (e.target.HTMLaddSourceFone.value).trim(),
                "email": (e.target.HTMLaddSourceEmail.value).trim(),
            }

            api.put('/source', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    alert(res.data.sucess)
                    setRefresh(!refresh);
                    setShow(false);

                    return;
                })
                .catch(error => {
                    setShow(false);
                    alert(error.response.data.error);
                    return;
                })
            ;

            return;
        }

    /*-------------------------------------------------------------------*/
    
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setShow(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setShow]);
    
    /*-------------------------------------------------------------------*/
    
    return(
        (show)
        ?
            <Modal>
                <FormEditSource onSubmit={ updateSource }>
                    <CloseButton onClick={ () => setShow(false) } >X</CloseButton>
                    <input name={ "HTMLid" } type={ "hidden" } defaultValue={ value.id } readOnly required/>
                    <LabelEditSource>
                        <b>Nome:</b>
                        <InputEditSouce autoFocus name={ "HTMLaddSourceName" } type={ "text" } maxLength={ 60 } defaultValue={ value.name } required/>
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Rua:</b>
                        <InputEditSouce name={ "HTMLaddSourceRoad" } type={ "text" } maxLength={ 60 } defaultValue={ value.road } />
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Número:</b>
                        <InputEditSouce name={ "HTMLaddSourceNumber" } type={ "number" } min={ 0 } defaultValue={ value.number } />
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Complemento:</b>
                        <InputEditSouce name={ "HTMLaddSourceComplement" } type={ "text" } maxLength={ 60 } defaultValue={ value.complement } />
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Bairro:</b>
                        <InputEditSouce name={ "HTMLaddSourceDistrict" } type={ "text" } maxLength={ 60 } defaultValue={ value.district } />
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Cep:</b>
                        <InputEditSouce 
                            name={ "HTMLaddSourceCep" } 
                            type={ "text" } 
                            maxLength={ 9 } 
                            defaultValue={ value.cep }
                            onChange={e => e.target.value = mask(e.target.value, '99999-999')}
                        />
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Estado:</b>
                        <SelectEditInput name={ "HTMLaddSourceState_country" } type={ "text" } maxLength={ 60 } defaultValue={ value.state_country } >
                            <option>MG</option> <option>SP</option> <option>RJ</option>
                            <option>AC</option> <option>AL</option> <option>AP</option>
                            <option>AM</option> <option>BA</option> <option>CE</option>
                            <option>ES</option> <option>GO</option> <option>MA</option>
                            <option>MT</option> <option>MS</option> <option>PA</option>
                            <option>PB</option> <option>PR</option> <option>PE</option>
                            <option>PI</option> <option>RN</option> <option>RS</option>
                            <option>RO</option> <option>RR</option> <option>SC</option>
                            <option>SE</option> <option>TO</option> <option>DF</option>
                        </SelectEditInput>
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Cidade:</b>
                        <InputEditSouce name={ "HTMLaddSourceCity" } type={ "text" } maxLength={ 60 } defaultValue={ value.city } />
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Telefone:</b>
                        <InputEditSouce 
                            name={ "HTMLaddSourceFone" } 
                            type={ "text" } 
                            maxLength={ 16 } 
                            defaultValue={ value.fone } 
                            required
                            onChange={ e => e.target.value = mask(e.target.value, '(99) 9 9999-9999') }
                        />
                    </LabelEditSource>
                    <LabelEditSource>
                        <b>Email:</b>
                        <InputEditSouce name={ "HTMLaddSourceEmail" } type={ "email" } maxLength={ 60 } defaultValue={ value.email } required/>
                    </LabelEditSource>
                    <UpdateEditSourceButton type={ 'submit' }>Atualizar</UpdateEditSourceButton>
                </FormEditSource>
            </Modal>
        :
            <ListEditSourceContainer>
                <p>{ value.name }</p>
                <ListButtonItem>
                    <ButtonComponent onClick={ () => setShow(true) } >Editar</ButtonComponent>
                    <EraseSource erase_id={ value.id } setRefresh={ setRefresh } refresh={ refresh }/>
                </ListButtonItem>
            </ListEditSourceContainer>
    )
}