import React, { useState, useEffect } from "react";
import api from "../../services/api";

import { 
    StyleListProtocol, 
    ContentListNavigation, 
    ContentButtonLeftListNavigation, 
    ContentButtonRigthListNavigation,
    ContentLabelListNavigation,
    CardListProtocol,
    CardGetProtocol,
    CardButton,
    ImageIndicator,
    OptionsContainer,
    InformationContainer,
    InformationCode,
    InformationParagraph,
    InformationParagraphDescribe,
    InformationLabel,
    Indicator,
    ToolTip
} from "./styles";

import On from "../../images/setting-on.png";
import Off from "../../images/setting-off.png";

import { Modal, ButtonComponent } from "../../source/styles/Other";

import Process from '../ListProcess';
import Search from "../Search";
import PrintProtocol from "../PrintProtocol/PrintProtocol";

export default function ListProtocol(){
    
    /*-------------------------------------------------------------------*/
    
        const [ data, setData ] = useState([{}]);
        const [ page, setPage ] = useState(1);
        const [ maxPage, setMaxPage ] = useState(0);
        const [ openProcess, setOpenProcess] = useState(false);
        const [ openProcessId, setOpenProcessId] = useState(null);
        const [ useSearch, setSearch ] = useState(false);
        const [ showStatus, setShowStatus ] = useState(true);
        const [ refresh, setRefresh ] = useState(false);

    /*-------------------------------------------------------------------*/

        function nextPage(){
            if(page < maxPage){
                setPage(page + 1);
                setOpenProcess(false);
            }
            return
        }

        function afterPage(){
            if(page > 1){
                setPage(page - 1);
                setOpenProcess(false);
            }
            return
        }

        function openProcessFunction(id){
            if(!openProcess){
                setOpenProcessId(id);
                setOpenProcess(true);
            }
            return;
        }

        function idOpenProtocol(id){

            const data = { id }

            api.post('protocol/unreceived', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    console.log(error);
                    alert(error.response.data.error);
                })
            ;

        }

        function idCloseProtocol(id){

            const data = { id }

            api.post('protocol/received', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    console.log(error);
                    alert(error.response.data.error);
                })
            ;
        }
    /*-------------------------------------------------------------------*/
        
        useEffect(() => {
            api.get(`protocol/page/${ page }`, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
                .then(res => {
                    setData(res.data.data);
                    setMaxPage(res.data.pages);
                })
                .catch(error => {
                    console.log(error);
                })
            ;
        },[page, refresh]);

    /*-------------------------------------------------------------------*/

    return(
        <StyleListProtocol>
            <Search 
                setData={ setData } 
                setSearch={ setSearch } 
                useSearch={ useSearch } 
                setPage={ setPage } 
                setOpenProcess={ setOpenProcess }
                setMaxPage={ setMaxPage }
            />
            {
                ( !useSearch ) &&
                <ContentListNavigation>
                    <ContentButtonLeftListNavigation type={ 'button' } onClick={ () => afterPage() }>Anterior</ContentButtonLeftListNavigation>
                    <ContentLabelListNavigation>Página { page }</ContentLabelListNavigation>
                    <ContentButtonRigthListNavigation type={ 'button' } onClick={ () => nextPage() }>Próxima</ContentButtonRigthListNavigation>
                </ContentListNavigation>
            }
            <CardListProtocol>
            {   
                (data.length)
                ?
                    data.map((value, index) => (
                        <div key={ index.toString() }>
                            <CardGetProtocol>
                                <OptionsContainer>
                                    <Indicator onClick={() => setShowStatus(!showStatus)}>
                                        {
                                            (value.open !== "0")
                                            ? <ImageIndicator src={ On } showStatus={showStatus} setShowStatus={setShowStatus} /> 
                                            : <ImageIndicator src={ Off } showStatus={showStatus} setShowStatus={setShowStatus} />
                                        }
                                        <ToolTip id="ToolTip" >
                                            {
                                                (value.open !== "0")
                                                ?
                                                    <div>
                                                        <h1>Protocolo aberto</h1>
                                                        <ButtonComponent type={"button"} onClick={() => idCloseProtocol(value.id)}>Fechar Protocolo</ButtonComponent>
                                                    </div>
                                                :
                                                    <div>
                                                        <h1>Protocolo fechado</h1>
                                                        <ButtonComponent type={"button"} onClick={() => idOpenProtocol(value.id)}>Abrir Protocolo</ButtonComponent>
                                                    </div>
                                            }
                                        </ToolTip>
                                    </Indicator>
                                    <PrintProtocol value={ value } />
                                </OptionsContainer>

                                <InformationContainer>  
                                    <InformationCode>Código: { value.code }</InformationCode>
                                    <InformationParagraph><InformationLabel>Status:</InformationLabel>  { value.state }</InformationParagraph>
                                    <InformationParagraph><InformationLabel>Cadastrado:</InformationLabel> { value.user_name_create }</InformationParagraph>
                                    <InformationParagraph><InformationLabel>Origem:</InformationLabel> { value.name_origin }</InformationParagraph>
                                    <InformationParagraph><InformationLabel>Destino:</InformationLabel> { value.name_destiny }</InformationParagraph>
                                    <InformationParagraph><InformationLabel>Data:</InformationLabel> { value.date}</InformationParagraph>
                                    <InformationParagraph><InformationLabel>Classificação:</InformationLabel> { value.classify }</InformationParagraph>
                                    <InformationParagraphDescribe><InformationLabel>Descrição:</InformationLabel> { value.describe }</InformationParagraphDescribe>
                                </InformationContainer>

                                <CardButton type={ 'button' } onClick={ () => openProcessFunction(value.id) }>Abrir Processos</CardButton> 
                            
                            </CardGetProtocol>
                        </div>
                    ))
                :
                <h3>Não há protocolos com esse valor</h3>
            }
            </CardListProtocol>
            { 
                (openProcess) &&
                <Modal>
                    <Process setOpenProcess={ setOpenProcess } openProcessId={ openProcessId } />
                </Modal>
            }
        </StyleListProtocol>
    )
}