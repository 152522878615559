import styled from "styled-components";

export const ContainerManagerMessagePredefined = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 1200px){
        font-size: 16px;    
    }

    @media (max-width: 1000px){
        font-size: 14px;    
    }
`;