import React, { useState, useEffect } from "react";
import api from "../../services/api";

import {
    FormEditMessagePredefined,
    InputEditMessagePredefined,
    UpdateEditMessagePredefined,
    ListEditMessagePredefined,
    ListButtonItemMessagePredefined
} from "./styles";

import EraseMessagePredefined from "../EraseMessagePredefined";

import { CloseButton, Modal, ButtonComponent } from "../../source/styles/Other";

export default function EditMessagePredefined({ value, refresh, setRefresh }){
    
    /*-------------------------------------------------------------------*/

        const [show, setShow] = useState(false);

    /*-------------------------------------------------------------------*/

        function updateMessagePredefined(e){
            e.preventDefault();

            const data = {
                "id": e.target.HTMLid.value,
                "message": e.target.HTMLmessagepredefined.value,
            }

            api.put('/predefined', data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    setShow(false);
                    alert(res.data.sucess);
                    setRefresh(!refresh);
                })
                .catch(error => {
                    alert(error.response.data.error);
                })

            return;
        }

        function getPreviewMessagePredefined(m){
            
            let preview = m ? m : "";

            preview = preview.substr(0,30);

            return preview + "..."
        }

    /*-------------------------------------------------------------------*/
        
        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setShow(false);
                }
            };
            window.addEventListener('keydown', handleEsc);
        }, [setShow]);

    /*-------------------------------------------------------------------*/

    return(
        (show)
        ?
            <Modal>
                <FormEditMessagePredefined onSubmit={ updateMessagePredefined }>
                    <CloseButton onClick={ () => setShow(false) } >X</CloseButton>
                    <input
                        name={ "HTMLid" } 
                        type={ "hidden" } 
                        defaultValue={ value.id } 
                        required 
                        readOnly
                    />
                    <InputEditMessagePredefined
                        autoFocus
                        name={ "HTMLmessagepredefined" } 
                        type={ "text" } 
                        defaultValue={ value.message } 
                        cols={ "30" } 
                        rows={ "10" }
                        maxLength={ 500 }
                        required
                    />
                    <UpdateEditMessagePredefined>Atualizar</UpdateEditMessagePredefined>
                </FormEditMessagePredefined>
            </Modal>
        :
            <ListEditMessagePredefined>
                <p>{ getPreviewMessagePredefined(value.message) }</p>
                <ListButtonItemMessagePredefined>
                    <ButtonComponent onClick={ () => setShow(true) } >Editar</ButtonComponent>
                    <EraseMessagePredefined erase_id={ value.id } setRefresh={ setRefresh } refresh={ refresh }/>
                </ListButtonItemMessagePredefined>
            </ListEditMessagePredefined>

    )
}