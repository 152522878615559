import React, { useEffect } from "react";
import api from "../../services/api";
import { mask } from "remask";
import { AddSourceForm, AddSourceInput, AddSourceLabel, AddSourceButton, AddSourceSelect } from "./styles";

import { CloseButton } from "../../source/styles/Other";

export default function AddSource({ setAddSource, refresh, setRefresh, sourceTipe, closeButton }) {
    /*-------------------------------------------------------------------*/
    async function insertSource(e) {
        e.preventDefault();

        const data = {
            name: e.target.HTMLaddSourceName.value.trim(),
            road: e.target.HTMLaddSourceRoad.value.trim(),
            number: e.target.HTMLaddSourceNumber.value.trim(),
            complement: e.target.HTMLaddSourceComplement.value.trim(),
            district: e.target.HTMLaddSourceDistrict.value.trim(),
            cep: e.target.HTMLaddSourceCep.value.trim(),
            state_country: e.target.HTMLaddSourceState_country.value.trim(),
            city: e.target.HTMLaddSourceCity.value.trim(),
            fone: e.target.HTMLaddSourceFone.value.trim(),
            email: e.target.HTMLaddSourceEmail.value.trim(),
            // source_type: e.target.source_type.trim(),
        };

        api.post("/source", data, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
            .then((res) => {
                console.log("AAAAAAAAAAAAAA", res);
                alert("Fonte adicionada");
                setRefresh(!refresh);
                setAddSource(false);
                return;
            })
            .catch((error) => {
                alert("Erro ao adicionar a Fonte: " + error.response.data.error);
                return;
            });

        return;
    }

    /*-------------------------------------------------------------------*/
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setAddSource(false);
            }
        };
        window.addEventListener("keydown", handleEsc);
    }, [setAddSource]);

    /*-------------------------------------------------------------------*/

    return (
        <AddSourceForm onSubmit={insertSource}>
            {closeButton && (
                <CloseButton type={"button"} onClick={() => setAddSource(false)}>
                    X
                </CloseButton>
            )}
            <AddSourceLabel>Registrar {sourceTipe}</AddSourceLabel>
            <AddSourceLabel>
                Nome:
                <AddSourceInput autoFocus name={"HTMLaddSourceName"} type={"text"} maxLength={60} required />
            </AddSourceLabel>
            <AddSourceLabel>
                Rua:
                <AddSourceInput name={"HTMLaddSourceRoad"} type={"text"} maxLength={60} />
            </AddSourceLabel>
            <AddSourceLabel>
                Número:
                <AddSourceInput name={"HTMLaddSourceNumber"} type={"number"} min={0} />
            </AddSourceLabel>
            <AddSourceLabel>
                Complemento:
                <AddSourceInput name={"HTMLaddSourceComplement"} type={"text"} maxLength={60} />
            </AddSourceLabel>
            <AddSourceLabel>
                Bairro:
                <AddSourceInput name={"HTMLaddSourceDistrict"} type={"text"} maxLength={60} />
            </AddSourceLabel>
            <AddSourceLabel>
                Cep:
                <AddSourceInput name={"HTMLaddSourceCep"} type={"text"} maxLength={9} onChange={(e) => (e.target.value = mask(e.target.value, "99999-999"))} />
            </AddSourceLabel>
            <AddSourceLabel>
                Estado:
                <AddSourceSelect name={"HTMLaddSourceState_country"} type={"text"} maxLength={60}>
                    <option>MG</option> <option>SP</option> <option>RJ</option>
                    <option>AC</option> <option>AL</option> <option>AP</option>
                    <option>AM</option> <option>BA</option> <option>CE</option>
                    <option>ES</option> <option>GO</option> <option>MA</option>
                    <option>MT</option> <option>MS</option> <option>PA</option>
                    <option>PB</option> <option>PR</option> <option>PE</option>
                    <option>PI</option> <option>RN</option> <option>RS</option>
                    <option>RO</option> <option>RR</option> <option>SC</option>
                    <option>SE</option> <option>TO</option> <option>DF</option>
                </AddSourceSelect>
            </AddSourceLabel>
            <AddSourceLabel>
                Cidade:
                <AddSourceInput name={"HTMLaddSourceCity"} type={"text"} maxLength={60} />
            </AddSourceLabel>
            <AddSourceLabel>
                Telefone:
                <AddSourceInput
                    name={"HTMLaddSourceFone"}
                    type={"text"}
                    maxLength={16}
                    required
                    onChange={(e) => {
                        if (e.target.value.replace(/[^0-9]/g, "").length < 11) {
                            e.target.value = mask(e.target.value, "(99) 9999-9999");
                        } else {
                            e.target.value = mask(e.target.value, "(99) 9 9999-9999");
                        }
                    }}
                />
            </AddSourceLabel>
            <AddSourceLabel>
                Email:
                <AddSourceInput name={"HTMLaddSourceEmail"} type={"email"} maxLength={60} required />
            </AddSourceLabel>
            <AddSourceButton type={"submit"}>Inserir</AddSourceButton>
        </AddSourceForm>
    );
}
