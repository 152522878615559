import React, { useState, useEffect } from "react";

import api from "../../services/api";

import { 
    AddButton, 
    ContainerAdd, 
    Modal,
    SimpleList
} from "../../source/styles/Other";

import {  
    ContainerManagerState
} from "./styles";

import AddState from "../AddState";
import EditState from "../EditState";

export default function ManagerState(){

    /*-------------------------------------------------------------------*/
            
        const [ data, setData ] = useState([{}]);
        const [ addState, setAddState ] = useState(false);
        const [ refresh, setRefresh ] = useState(false);

    /*-------------------------------------------------------------------*/

        useEffect(() => {
            api.get('/state', { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") } })
                .then(res => {
                    setData(res.data);
                })
                .catch(error => {
                    console.log(error.response.data.error);
                })
            ;
        },[refresh]);

    /*-------------------------------------------------------------------*/

    return(
        <ContainerManagerState>
            <ContainerAdd>
                <AddButton type={ "button" } onClick={ () => setAddState(!addState) }>{ (addState)? <p>-</p> : <p>+</p> }</AddButton>
            </ContainerAdd>
            <SimpleList>
                {
                    data.map((value, index) => (
                        <div key={ index.toString() }>
                            <EditState refresh={ refresh } setRefresh={ setRefresh } value={ value } />
                        </div>
                    ))
                }   
            </SimpleList>
            {
                (addState) &&
                <Modal>
                    <AddState 
                        setAddState={ setAddState } 
                        refresh={ refresh } 
                        setRefresh={ setRefresh } 
                        closeButton={ true }
                    />
                </Modal>
            }
        </ContainerManagerState>
    )
}