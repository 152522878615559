import styled from "styled-components";
import { theme } from "../../source/theme";

export const RegisterContainer = styled.div`
    display: grid;
    grid-template-areas: 
        "register"
        /* "preview" */
    ;
    /* grid-template-rows: 2fr 1fr;   */

    @media (max-width: 1500px){
        grid-template-areas: 
            "register"
            /* "preview" */
        ;
        /* grid-template-rows: 2fr 2fr 1fr; */
    }

    justify-content: center;
    align-items: flex-start;

    min-width: 400px;
    padding: 5px;

    overflow-y: auto;
    ::-webkit-scrollbar{
        width: 4px;
        height: 4px;
    };
    
    ::-webkit-scrollbar-thumb{
        background: ${ theme.secundary };
    };

`;

export const RegisterForm = styled.form`
    grid-area: register;
    display: flex;
    flex-direction: column;
    
    gap: 10px;
    
    padding: 20px;
    box-shadow: ${ theme.secundary } 0px 3px 8px;

    width: 600px;

    margin-bottom: 40px;
    margin-right: 40px;
    
    @media (max-width: 1500px){
        margin-right: 0px;
    }
    
    @media (max-width: 900px){
        width: 500px;
    }

    @media (max-width: 800px){
        width: 400px;
    }

    @media (max-width: 700px){
        width: 300px;
    }
`;

export const RegisterFormLabel = styled.label`
    display: flex;
    flex-direction: column;
`;

export const RegisterFormLabelDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const RegisterFormSelect = styled.select`
    display: flex;
    width: 100%;
    padding: 5px;

    ::-webkit-scrollbar{
        width: 4px;
        height: 4px;
    };
    
    ::-webkit-scrollbar-thumb{
        background: ${ theme.secundary };
    };
`;

export const RegisterFormOption = styled.option`
    /* background-color: ${ theme.ternary }; */
`;

export const RegisterAddButton = styled.button`
    width: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RegisterFormTextarea = styled.textarea`
    margin-top: 5px;
    padding: 10px;
`;

export const PreviewContainer = styled.div`
    grid-area: preview;

    justify-self: center;
`;

export const RegisterButton = styled.button`
    background-color: ${ theme.secundary };
    color: ${ theme.primary };

    padding-top: 10px;
    padding-bottom: 10px;

    :hover{
        transition: 500ms;
        background-color: ${ theme.primary };
        color: ${  theme.secundary };
    }
`;

export const AddAreaContainer = styled.div`
    
`;