import styled from "styled-components";
import { theme } from "../../source/theme";


export const AddStateForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding: 10px;
    min-width: 300px;

    box-shadow: ${ theme.secundary } 0px 3px 8px;

    background-color: ${ theme.ternary };
`;

export const AddStateLabel = styled.label`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const AddStateInput = styled.input`
    padding: 5px;
`;

export const AddStateButton = styled.button`
    background-color: ${ theme.secundary };
    color: ${ theme.primary };

    padding-top: 5px;
    padding-bottom: 5px;

    :hover{
        transition: 500ms;
        background-color: ${ theme.primary };
        color: ${  theme.secundary };
    }
`;