import React, { useState, useEffect } from "react";
import api from "../../services/api";
import {
    EditProtocolContainer,
    ContentButtonLeftListNavigation,
    ContentButtonRigthListNavigation,
    ContentLabelListNavigation,
    ContentListNavigation,
    ModuleEditProtocolArea
} from "./styles";

import ModuleEditProtocol from '../ModuleEditProtocol';

export default function EditProtocol(){

    /*-------------------------------------------------------------------*/
    
    const [ data, setData ] = useState([{}])
    const [ page, setPage ] = useState(1);
    const [ maxPage, setMaxPage ] = useState(0);
    const [ refreshProtocols, setRefreshProtocols ] = useState(false);

    /*-------------------------------------------------------------------*/

    function nextPage(){
        if(page < maxPage){
            setPage(page + 1);
        }
        return
    }

    function afterPage(){
        if(page > 1){
            setPage(page - 1);
        }
        return
    }

    /*-------------------------------------------------------------------*/

    useEffect(() => {
        api.get(`protocol/page/${page}`, { headers: { My__Authorization: localStorage.getItem("token-user-prosif-current") }})
            .then(res => {
                setData(res.data.data);
                setMaxPage(res.data.pages);
            })
            .catch(error => {
                console.log(error);
            })
        ;
    },[page, refreshProtocols]);

    /*-------------------------------------------------------------------*/

    return(
        <EditProtocolContainer>
            <ContentListNavigation>
                    <ContentButtonLeftListNavigation type={ 'button' } onClick={ () => afterPage() }>Anterior</ContentButtonLeftListNavigation>
                    <ContentLabelListNavigation>Página { page }</ContentLabelListNavigation>
                    <ContentButtonRigthListNavigation type={ 'button' } onClick={ () => nextPage() }>Próxima</ContentButtonRigthListNavigation>
            </ContentListNavigation>
            <ModuleEditProtocolArea>
                { 
                    data.map((value, index) => (
                        <ModuleEditProtocol 
                            key={ index.toString() } 
                            value={ value } 
                            index={ index } 
                            refreshProtocols={ refreshProtocols } 
                            setRefreshProtocols={ setRefreshProtocols } 
                        />
                    ))
                }
            </ModuleEditProtocolArea>
        </EditProtocolContainer>
    )
}